import { Box, Container, Typography } from "@mui/material";

import AboutUsImage from "../../assets/about-us.png";
import AboutUsImageMobile from "../../assets/about-us-mobile.png";
import { StyledHomButton } from "./LandingBanner";
import { home_labels } from "../../constants";

const AboutUsSection = () => {
  return (
    <Box
      id="about-us"
      sx={{
        position: "relative",
        background: "#F7F8FA",
        mt: {
          xs: "50px",
          md: "50px"
        },
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        height: {
          xs: 590,
          md: 572
        }
      }}
    >
      <Box
        sx={{
          position: {
            xs: "absolute",
            md: "relative"
          },
          width: "100%"
        }}
      >
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none"
            }
          }}
        >
          <img
            loading="lazy"
            src={AboutUsImageMobile}
            alt={home_labels.aboutUsTitleText}
            width={"100%"}
            height={220}
            style={{
              objectFit: "cover"
            }}
          />
        </Box>
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xs: "0 20px",
              md: "0 95px"
            },
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            width: "100%"
          }}
        >
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex"
              },
              mr: "61px",
              mt: "69px"
            }}
          >
            <img
              src={AboutUsImage}
              alt={home_labels.aboutUsTitleText}
              height={"100%"}
              width={"100%"}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: 26,
                  md: 30
                },
                lineHeight: {
                  xs: "40px",
                  md: "64px"
                },
                mt: {
                  xs: -33,
                  md: 0
                },
                mb: {
                  xs: 26,
                  md: 0
                },
                background: "#F7F8FA",
                letterSpacing: "-0.02em"
              }}
            >
              {home_labels.aboutUsTitleText}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: 15,
                  md: 16
                },
                lineHeight: {
                  xs: "22.5px",
                  md: "24px"
                },
                letterSpacing: "-0.01em",
                mt: {
                  xs: "20px",
                  md: "40px"
                },
                maxWidth: {
                  xs: "100%",
                  md: 444
                },
                textAlign: "justify"
              }}
              color={"text.secondary"}
            >
              Our{" "}
              <strong>
                <em>Mission</em>
              </strong>{" "}
              is to become{" "}
              <strong>
                <em>'A One Stop Supply Chain Solution'</em>
              </strong>{" "}
              to all SMEs. Our SAAS based marketplace enables Distributors,
              Dealers, Wholesalers and Small manufacturers to grow/ expand to
              new territories, handle new products/ brands/ SKUs by reducing
              their distribution costs.
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: 15,
                  md: 16
                },
                lineHeight: {
                  xs: "22.5px",
                  md: "24px"
                },
                letterSpacing: "-0.01em",
                mt: {
                  xs: "20px",
                  md: 5
                },
                mb: {
                  xs: "32px",
                  md: "52px"
                },
                maxWidth: {
                  xs: "100%",
                  md: 444
                },
                textAlign: "justify"
              }}
              color={"text.secondary"}
            >
              {
                "We are an energetic team with a combined experience of more than 30 years across various industries passionate to bring a change in the Supply Chain Tech Industry."
              }
            </Typography>
            <Box
              sx={{
                borderRadius: 1,
                overflow: "hidden",
                width: "fit-content"
              }}
            >
              <StyledHomButton isLanding={true}>
                {home_labels.learnMoreButtonText}
              </StyledHomButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUsSection;
