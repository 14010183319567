import { Box, Container, Divider, Grid, Typography } from "@mui/material";

import SolutionCardItem from "./SolutionCardItem";
import SolutionCardItemMobile from "./SolutionCardItemMobile";
import { home_labels } from "../../constants";

import Search from "../../assets/SearchIcon.png";
import Match from "../../assets/MatchIcon.png";
import RFQ from "../../assets/RFQIcon.png";
import Book from "../../assets/BookIcon.png";
import Manage from "../../assets/ManageIcon.png";
import Retrieve from "../../assets/RetrieveIcon.png";
const solutions = [
  {
    text: home_labels.searchText,
    subText: home_labels.searchSubText,
    icon: Search
  },
  {
    text: home_labels.findText,
    subText: home_labels.findSubText,
    icon: Match
  },
  {
    text: home_labels.rfqText,
    subText: home_labels.rfqSubText,
    icon: RFQ
  },
  {
    text: home_labels.bookText,
    subText: home_labels.bookSubText,
    icon: Book
  },
  {
    text: home_labels.manageText,
    subText: home_labels.manageSubText,
    icon: Manage
  },
  {
    text: home_labels.shipText,
    subText: home_labels.shipSubText,
    icon: Retrieve
  }
];

export const SectionHeader = ({ title, subTitle, props }) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: {
            xs: 26,
            md: 36
          },
          lineHeight: {
            xs: "40px",
            md: "64px"
          },
          letterSpacing: "-0.02em",
          marginTop: {
            xs: "20px"
          },
          ...props
        }}
        textAlign={"center"}
        gutterBottom
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 15,
            md: 16
          },
          lineHeight: {
            xs: "22.5px",
            md: "24px"
          },
          letterSpacing: "-0.01em",
          padding: "0 20px"
        }}
        textAlign={"center"}
        color={"text.secondary"}
      >
        {subTitle}
      </Typography>
    </>
  );
};

const SolutionCards = (props) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      justifyContent={"center"}
      sx={{
        mt: {
          xs: "10px",
          md: "39px"
        }
      }}
      {...props}
    >
      <SectionHeader
        title={home_labels.howWeSolveText}
        subTitle={home_labels.howWeSolveSubText}
      />
      <Box
        sx={{
          mt: "60px",
          width: "100%",
          background: "#F7F8FA",
          display: {
            xs: "none",
            lg: "block"
          }
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xs: 0,
              md: "0 95px !important"
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          {solutions.map(
            (item, index) =>
              index < 3 && <SolutionCardItem key={item.text} {...item} />
          )}
        </Container>
        <Divider
          sx={{
            borderColor: "white",
            borderBottomWidth: "2px"
          }}
        />
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xs: 0,
              md: "0 95px !important"
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          {solutions.map(
            (item, index) =>
              index > 2 && <SolutionCardItem key={item.text} {...item} />
          )}
        </Container>
      </Box>
      <Box
        sx={{
          mt: "24px",
          width: "100%",
          display: {
            xs: "block",
            lg: "none"
          },
          padding: "0 20px"
        }}
      >
        <Grid container spacing="4px">
          {solutions.map((item) => (
            <Grid item xs={6} md={4} key={item.text}>
              <SolutionCardItemMobile {...item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SolutionCards;
