import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import ERPHeader from "./ERPHeader";
import ERPHomeButtons from "./ERPHomeButtons";
import { useInView } from "react-intersection-observer";

const ERPSliderComponent = () => {
  const { ref: desktopRef, inView: desktopInView } = useInView({
    threshold: 0.5
  });

  const { ref: mobileRef, inView: mobileInView } = useInView({
    threshold: 0.5
  });

  const desktopIframeRef = useRef(null);
  const mobileIframeRef = useRef(null);

  useEffect(() => {
    const handlePlayPause = (iframe, action) => {
      if (iframe) {
        const message =
          action === "play"
            ? '{"event":"command","func":"playVideo","args":""}'
            : '{"event":"command","func":"pauseVideo","args":""}';
        iframe.contentWindow.postMessage(message, "*");
      }
    };

    if (!desktopInView) {
      handlePlayPause(desktopIframeRef.current, "pause");
    }
    if (!mobileInView) {
      handlePlayPause(mobileIframeRef.current, "pause");
    }
  }, [desktopInView, mobileInView]);

  return (
    <>
      <Box
        ref={desktopRef}
        sx={{
          padding: "0 20px 0 90px !important",
          display: {
            xs: "none",
            md: "flex"
          }
        }}
        gap={2}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "55px"
          }}
        >
          <ERPHeader />
          <ERPHomeButtons />
        </Box>
        <Box display={"flex"} alignItems={"center"} sx={{ width: "50%" }}>
          <iframe
            ref={desktopIframeRef}
            loading="lazy"
            id="desktop-iframe"
            title="ERP for SMEs"
            width="600"
            height="420"
            src="https://www.youtube.com/embed/s3w6fEdlU18?enablejsapi=1&playlist=s3w6fEdlU18&loop=1"
            style={{
              border: "none"
            }}
          ></iframe>
        </Box>
      </Box>
      <Box
        ref={mobileRef}
        sx={{
          display: {
            xs: "flex",
            md: "none"
          },
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          padding: "0 20px !important",
          gap: 2
        }}
      >
        <ERPHeader />
        <Box
          ref={mobileIframeRef}
          id="mobile-iframe"
          component={"iframe"}
          title="ERP for SMEs"
          display={"flex"}
          justifyContent={"center"}
          minHeight={"320px"}
          minWidth={"240px"}
          src="https://www.youtube.com/embed/s3w6fEdlU18?enablejsapi=1&playlist=s3w6fEdlU18&loop=1"
        />
        <ERPHomeButtons />
      </Box>
    </>
  );
};

export default ERPSliderComponent;
