import { Box, Typography } from "@mui/material";

import { home_labels } from "../../constants";
import { isMobileDevice } from "../../App";

const ERPHeader = () => {
  return (
    <Box
      className="header_group"
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: { xs: "10px", md: "28px" } }}
    >
      <Typography
        component={isMobileDevice ? "p" : "span"}
        sx={{
          fontSize: {
            xs: 24,
            md: 48
          },
          lineHeight: {
            xs: "40px",
            md: "64px"
          },
          fontWeight: 700,
          letterSpacing: "-0.02em",
          color: "text.title",
          textRendering: "optimizeLegibility"
        }}
      >
        {home_labels.erpTitleText}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 20
          },
          lineHeight: {
            xs: "21px",
            md: "64px"
          },
          letterSpacing: {
            xs: "-0.01em",
            md: "-0.02em"
          },
          color: {
            xs: "text.secondary",
            md: "text.primary"
          },
          fontWeight: "400",
          textAlign: { xs: "justify", md: "left" },
          textRendering: "optimizeLegibility"
        }}
      >
        {home_labels.erpTitleSubtext}
      </Typography>
    </Box>
  );
};

export default ERPHeader;
