import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import PricingImage from "../../assets/Pricing.jpeg";
import BasicPlan from "../../assets/Basic_subscription.jpeg";
import StandardPlan from "../../assets/Standard_Subscription.jpeg";
import EnterPricePlan from "../../assets/Enterprise_subscription.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const pricingPlans = [
  {
    image: BasicPlan,
    id: "Basic"
  },
  {
    image: StandardPlan,
    id: "Standard"
  },
  {
    image: EnterPricePlan,
    id: "EnterPrice"
  }
];

const ImageComponent = ({ children }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden"
      }}
      justifyContent={"center"}
    >
      {children}
    </Box>
  );
};

const Pricing = () => {
  const sectionRef = useRef(null);
  const sliderRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      const entry = entries[0];
      setIsInView(entry.intersectionRatio >= 0.5);
    };

    const observerOptions = {
      threshold: [0.5]
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      if (isInView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [isInView]);

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current && isInView) {
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false, // Default to false
    autoplaySpeed: 2000
  };

  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Box
        ref={sectionRef}
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" },
          gap: 2
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "14px", md: "22px" },
            lineHeight: { xs: "21px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={400}
        >
          {labels.pricingInfo}
        </Typography>
        {isMobile ? (
          <Slider ref={sliderRef} {...settings}>
            {pricingPlans.map((plan) => (
              <Grid item xs={12} key={plan.id}>
                <ImageComponent>
                  <img
                    src={plan.image}
                    alt={plan.id}
                    style={{
                      width: 260,
                      height: 376,
                      objectFit: "contain" // Ensures the entire image fits within the box
                    }}
                  />
                </ImageComponent>
              </Grid>
            ))}
          </Slider>
        ) : (
          <Box
            display={"flex"}
            padding={"0px 90px"}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              src={PricingImage}
              alt={"Pricing"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
        )}
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "14px", md: "22px" },
            lineHeight: { xs: "21px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify",
            paddingTop: { xs: "10px", md: "20px" },
            mb: { xs: 0, md: -4 }
          }}
          fontWeight={700}
        >
          {labels.contactUsforDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};

export default Pricing;
