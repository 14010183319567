import { Box, IconButton } from "@mui/material";

import AboutUsSection from "../components/AboutUsSection";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import IndustriesSection from "../components/IndustriesSection";
import { ERPSection } from "../components/ERPSection";
import { MarketplaceSection } from "../components/MarketplaceSection";
import SolutionsSection from "../components/SolutionsSection";
import { home_labels } from "../../constants";
import { CreditSection } from "../components/CreditSection";
import { useEffect, useRef, useState } from "react";
import { scroller } from "react-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MarketplaceSliderComponent from "../components/MarketplaceSliderComponent";
import ERPSliderComponent from "../components/ERPSliderComponent";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  sx: {
    ".slick-slider": {
      overflow: "hidden"
    },
    ".slick-list": {
      padding: 0,
      margin: 0,
      overflow: "hidden"
    },
    ".slick-track": {
      display: "flex"
    },
    ".slick-slide": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    ".slick-slide img": {
      width: "100%",
      height: "auto",
      objectFit: "cover"
    }
  }
};

const Landing = () => {
  const sliderRef = useRef(null);
  const sectionRef = useRef(null);
  const [isInView, setIsInView] = useState(true); // Start true to ensure slider starts autoplay

  // Handle observer to pause slider when it's not in view
  useEffect(() => {
    const observerCallback = (entries) => {
      const entry = entries[0];
      setIsInView(entry.intersectionRatio >= 0.4);
    };

    const observerOptions = {
      threshold: [0.4]
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  // Start the slider autoplay on mount, and control with inView state
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Ensure autoplay starts as soon as component mounts
    }
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      if (isInView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [isInView]);

  // Pause slider on hover or touch
  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  // Resume autoplay if the slider is in view
  const handleMouseLeave = () => {
    if (sliderRef.current && isInView) {
      sliderRef.current.slickPlay();
    }
  };

  // Scroll to a specific section if URL contains an ID
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sectionId = urlParams.get("id");
    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70
      });
    }
  }, []);

  const handleNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <Box
      sx={{
        mt: {
          xs: "17px",
          md: "81px"
        },
        position: "relative",
        overflow: "hidden"
      }}
    >
      <Box
        id={"Landing-Header"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={sectionRef}
        style={{ position: "relative" }}
      >
        <Slider ref={sliderRef} {...sliderSettings}>
          <ERPSliderComponent />
          <MarketplaceSliderComponent />
        </Slider>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            transform: "translateY(-50%)",
            zIndex: 1
          }}
        >
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "#F9A90E"
            }}
            onClick={handlePreviousSlide}
          >
            <ArrowBackIosNew style={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "#F9A90E"
            }}
            onClick={handleNextSlide}
          >
            <ArrowForwardIos style={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      </Box>
      <SolutionsSection />
      <MarketplaceSection />
      <ERPSection />
      <CreditSection />
      <IndustriesSection />
      <AboutUsSection />
      <ContactUsSection title={home_labels.contactUsTitleText} />
      <Footer />
    </Box>
  );
};

export default Landing;
