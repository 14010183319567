import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { Button, Grid } from "@mui/material";
import { CalendarTodayOutlined, ViewInAr } from "@mui/icons-material";
import { useEffect, useState } from "react";

import AutoCompleteMapField from "../../shared/formElements/AutoCompleteMapField";
import DateInput from "../../shared/formElements/DateInput";
import DropDown from "../../shared/formElements/DropDown";
import { SearchFormKeys } from "../../constants/formKeys";
import Storage from "../../shared/utils/Storage";
import { searchFormType, StorageKeys } from "../../constants/storage-keys";
import TextInput from "../../shared/formElements/TextInput";
import { getDistance } from "../../shared/utils/Distance";
import { error_msg, filter_labels, labels } from "../../constants";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTransportSearchFormDataHook } from "../../query-hooks/Transport/useTransportSearchFormDataHook";
import { useWarehouseSearchFormDataHook } from "../../query-hooks/Warehouse/useWarehouseSearchFormDataHook";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDrawer } from "../../shared/customSideDrawer";

const schema = (warehouseService) => {
  const baseSchema = {};

  if (warehouseService) {
    baseSchema[SearchFormKeys.StorageType] = yup
      .string()
      .required(error_msg.required);
    baseSchema[SearchFormKeys.pallets] = yup
      .string()
      .required(error_msg.required);
    baseSchema["Dropdown_Storage_Quantity"] = yup
      .string()
      .when(
        [SearchFormKeys.StorageType, SearchFormKeys.pallets],
        (StorageType, schema) => {
          return !Boolean(StorageType[0]) || !Boolean(StorageType[1])
            ? schema.required(error_msg.required)
            : schema.notRequired();
        }
      );
    baseSchema[SearchFormKeys.InboundDate] = yup
      .array()
      .of(
        yup
          .date()
          .min(moment().startOf("day").toDate(), error_msg.required)
          .required(error_msg.required)
      )
      .length(2, error_msg.required)
      .test("is-valid-range", error_msg.required, ([from, to]) =>
        moment(to).isAfter(from)
      )
      .required(error_msg.required);
  } else {
    baseSchema[SearchFormKeys.PickupLocation] = yup
      .string()
      .required(error_msg.required);
    baseSchema[SearchFormKeys.Destination] = yup
      .string()
      .required(error_msg.required);
    baseSchema[SearchFormKeys.PickUpDate] = yup
      .date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .min(
        moment(new Date()).startOf("day").toDate(),
        "Pickup date must be today or later"
      )
      .required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const DropdownStorageAndQuantity = ({
  control,
  watch,
  service,
  data,
  isFetching,
  defaultValues = ""
}) => {
  const [val, setVal] = useState(defaultValues);
  useEffect(() => {
    const subscription = watch((value) => {
      setVal(
        `${value[SearchFormKeys.StorageType] || ""} | ${
          value[SearchFormKeys.pallets] || ""
        }`
      );
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const renderComponentValue = val;

  return (
    <DropDown
      mandatoryField
      name={"Dropdown_Storage_Quantity"}
      label={filter_labels.storageQuantity}
      control={control}
      renderComponentValue={renderComponentValue}
      placeholder={"Select Storage Quantity"}
      filterStyle={{
        fontWeight: "500 !important",
        color: service?.warehouse ? "#2773FF !important" : "#F9A90E !important",
        fontSize: "12px !important"
      }}
      renderComponentOptions={[
        <DropDown
          name={SearchFormKeys.StorageType}
          control={control}
          placeholder={labels.spaceTypePlaceholderText}
          label={labels.spaceTypeLabelText}
          options={data?.storageTypes}
          isLoading={isFetching}
        />,
        <TextInput
          type="number"
          control={control}
          name={SearchFormKeys.pallets}
          label={labels.quantityPallets}
          placeholder={labels.spaceOrPalletsAccommodatedPlaceholderText}
        />
      ]}
      startIcon={ViewInAr}
    />
  );
};

const DropdownServiceAndWeight = ({
  control,
  watch,
  service,
  data,
  formType,
  defaultValues = ""
}) => {
  const [val, setVal] = useState(defaultValues);
  useEffect(() => {
    const subscription = watch((value) => {
      setVal(
        `${value[SearchFormKeys.AdditionalFeature]?.join(",") || ""} | ${
          value[SearchFormKeys.totalWeight] || ""
        } Ton`
      );
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const renderComponentValue = val;

  return (
    <DropDown
      name={"Dropdown_service_weight"}
      label={filter_labels.services}
      control={control}
      renderComponentValue={renderComponentValue}
      placeholder={"Select other services"}
      filterStyle={{
        fontWeight: "500 !important",
        color: service?.warehouse ? "#2773FF !important" : "#F9A90E !important",
        fontSize: "12px !important"
      }}
      renderComponentOptions={[
        <CheckBoxSelect
          label={labels.additionalServicesLabelText}
          control={control}
          name={SearchFormKeys.AdditionalFeature}
          options={data?.additionalServices.filter((item) =>
            item.partnerTypes.includes(formType)
          )}
        />,
        <TextInput
          control={control}
          name={SearchFormKeys.totalWeight}
          label={labels.totalWeightOfMaterialLabelText}
          placeholder={labels.totalWeightOfMaterialPlaceholderText}
        />
      ]}
      startIcon={ViewInAr}
    />
  );
};

const Filters = ({ applyFilters }) => {
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);
  const FORM_DATA = Storage.getItem(StorageKeys.SEARCH_FORM_DATA);
  const listingFormParams = Storage.getItem(StorageKeys.SEARCH_LISTING_PARAMS);
  const LISTING_FORM_DATA = Storage.getItem(StorageKeys.SEARCH_LISTING_DATA);

  const formType =
    Storage.getItem(StorageKeys.SEARCH_FORM_TYPE) ===
    searchFormType.findByWarehouseForm
      ? "Warehouse"
      : "Transport";

  const [pickupLocationCoordinates, setPickupLocationCoordinates] = useState(
    FORM_DATA?.pickupLocationCoordinates || []
  );

  const [destinationCoordinates, setDestinationCoordinates] = useState(
    FORM_DATA?.destinationCoordinates || []
  );

  const { hideDrawer } = useDrawer();

  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: Boolean(FORM_DATA)
      ? {
          ...FORM_DATA,
          ...(service?.warehouse
            ? {
                [SearchFormKeys.InboundDate]: [
                  Boolean(FORM_DATA[SearchFormKeys.InboundDate]) &&
                    new Date(FORM_DATA[SearchFormKeys.InboundDate]),
                  Boolean(FORM_DATA[SearchFormKeys.OutboundDate]) &&
                    new Date(FORM_DATA[SearchFormKeys.OutboundDate])
                ]
              }
            : {
                [SearchFormKeys.PickUpDate]:
                  Boolean(FORM_DATA[SearchFormKeys.PickUpDate]) &&
                  new Date(FORM_DATA[SearchFormKeys.PickUpDate])
              })
        }
      : {},
    resolver: yupResolver(schema(service?.warehouse))
  });

  const { data: warehouseData, isFetching: isWarehouseFetching } =
    useWarehouseSearchFormDataHook(service?.warehouse);

  const { data: transportData, isFetching: isTransportFetching } =
    useTransportSearchFormDataHook(service?.transport);

  const data = service?.warehouse ? warehouseData : transportData;
  const isFetching = service?.warehouse
    ? isWarehouseFetching
    : isTransportFetching;

  useEffect(() => {
    const subscription = watch(() => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });

    const distance = getDistance(
      pickupLocationCoordinates[0],
      pickupLocationCoordinates[1],
      destinationCoordinates[0],
      destinationCoordinates[1]
    );

    const { inboundDate, destination, ...rest } = data;
    Storage.setItem(StorageKeys.SEARCH_FORM_DATA, {
      ...LISTING_FORM_DATA,
      ...rest,
      ...{
        ...(Boolean(inboundDate)
          ? {
              inboundDate: new Date(inboundDate[0]),
              outboundDate: new Date(inboundDate[1])
            }
          : {})
      },
      ...{
        ...(destination !== ""
          ? {
              destination: destination
            }
          : {})
      },
      pickupLocationCoordinates: [...pickupLocationCoordinates],
      destinationCoordinates: [...destinationCoordinates],
      distance: distance
    });

    const formObject = {
      ...listingFormParams,
      ...(formType === "Transport" && {
        "searchForm[inboundDate]": moment(data.pickupDate).format("YYYY-MM-DD"),
        "searchForm[pickup][address]": data.pickupLocation,
        "searchForm[pickup][location][coordinates]": [
          ...pickupLocationCoordinates
        ],
        "searchForm[distance]": distance
      }),
      ...(data?.destination !== "" && {
        "searchForm[destination][address]": data.destination,
        "searchForm[destination][location][coordinates]": [
          ...destinationCoordinates
        ]
      }),
      ...(formType === "Warehouse" && {
        "searchForm[inboundDate]": moment(data.inboundDate[0]).format(
          "YYYY-MM-DD"
        ),
        "searchForm[outboundDate]": moment(data.inboundDate[1]).format(
          "YYYY-MM-DD"
        ),
        "searchForm[storageType]": data.storageType,
        "searchForm[storageQuantity]": data.pallets
      })
    };

    // Add only fields with non-empty values
    const additionalFields = {
      "searchForm[materialCategory]": data.materialDetails,
      "searchForm[packagingType]": data.packagingType,
      "searchForm[packageDimension]": data.dimension,
      "searchForm[packageQuantity]": data.units,
      "searchForm[totalWeight]": data.totalWeight,
      "searchForm[additionalServices]": data.additionalFeature
    };

    for (const [key, value] of Object.entries(additionalFields)) {
      if (value !== undefined && value !== null && value !== "") {
        formObject[key] = value;
      }
    }

    applyFilters(formObject);
    Storage.setItem(StorageKeys.SEARCH_FORM_PARAMS, formObject);
    hideDrawer();
  };

  // const DropdownPackagingTypeMaterialDetails = () => {
  //   const packagingTypeName = data?.packagingTypes?.find(
  //     (item) => item.name === getValues(SearchFormKeys.PackagingType)
  //   )?.displayName;

  //   const materialDetailsName = data?.materialCategories?.find(
  //     (item) => item.name === getValues(SearchFormKeys.MaterialDetails)
  //   )?.displayName;

  //   return (
  //     <DropDown
  //       name={"Dropdown_PackagingType_MaterialDetails"}
  //       control={control}
  //       renderComponentValue={[packagingTypeName, materialDetailsName].join(
  //         " | "
  //       )}
  //       renderComponentOptions={[
  //         <DropDown
  //           name={SearchFormKeys.PackagingType}
  //           control={control}
  //           placeholder={labels.packagingPlaceholderText}
  //           label={labels.packagingTypeLabelText}
  //           options={data?.packagingTypes}
  //           isLoading={isFetching}
  //         />,
  //         <DropDown
  //           name={SearchFormKeys.MaterialDetails}
  //           control={control}
  //           placeholder={labels.materialDetailsPlaceholderText}
  //           label={labels.materialDetailsLabelText}
  //           options={data?.materialCategories}
  //           isLoading={isFetching}
  //         />
  //       ]}
  //       startIcon={ViewInAr}
  //     />
  //   );
  // };

  // const DropdownDimensionUnits = () => {
  //   const Dimension = data?.packageDimensions?.find(
  //     (item) => item.name === getValues(SearchFormKeys.Dimension)
  //   )?.displayName;

  //   const Units = getValues(SearchFormKeys.Units);

  //   return (
  //     <DropDown
  //       name={"Dropdown_Dimension_Units"}
  //       control={control}
  //       renderComponentValue={[Dimension, Units].join(" | ")}
  //       renderComponentOptions={[
  //         <DropDown
  //           name={SearchFormKeys.Dimension}
  //           control={control}
  //           placeholder={labels.dimensionPlaceholderText}
  //           label={labels.dimensionLabelText}
  //           options={data?.packageDimensions}
  //           isLoading={isFetching}
  //         />,
  //         <TextInput
  //           control={control}
  //           name={SearchFormKeys.Units}
  //           label={labels.noOfPackagesLabelText}
  //           placeholder={labels.noOfPackagesPlaceholderText}
  //         />
  //       ]}
  //       startIcon={ViewInAr}
  //     />
  //   );
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        sx={{ pb: 3 }}
        display={"flex"}
        alignItems={"flex-end"}
      >
        {service?.warehouse ? (
          <Grid item xs={12} md={2.5}>
            <AutoCompleteMapField
              control={control}
              name={SearchFormKeys.Destination}
              label={filter_labels.selectWarehouseLocation}
              placeholder={labels.destinationPlaceholderText}
              reset={(address, location) => {
                setValue(SearchFormKeys.Destination, address);
                setDestinationCoordinates([location.lat, location.lng]);
              }}
              sx={{
                fontWeight: "500 !important",
                color: "#2773FF !important",
                fontSize: "12px !important"
              }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={2.25}>
              <AutoCompleteMapField
                control={control}
                label={filter_labels.selectPickupLocation}
                name={SearchFormKeys.PickupLocation}
                placeholder={labels.pickupLocationPlaceholderText}
                mandatoryField
                reset={(address, location) => {
                  setValue(SearchFormKeys.PickupLocation, address);
                  setPickupLocationCoordinates([location.lat, location.lng]);
                }}
                sx={{
                  fontWeight: "500 !important",
                  color: "#F9A90E !important",
                  fontSize: "12px !important"
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.25}>
              <AutoCompleteMapField
                control={control}
                label={filter_labels.selectDropLocation}
                name={SearchFormKeys.Destination}
                placeholder={labels.destinationPlaceholderText}
                mandatoryField
                reset={(address, location) => {
                  setValue(SearchFormKeys.Destination, address);
                  setDestinationCoordinates([location.lat, location.lng]);
                }}
                sx={{
                  fontWeight: "500 !important",
                  color: "#F9A90E !important",
                  fontSize: "12px !important"
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={service?.warehouse ? 2.5 : 2}>
          {service?.warehouse ? (
            <DateInput
              mandatoryField
              selectsRange={service?.warehouse}
              control={control}
              name={SearchFormKeys.InboundDate}
              label={filter_labels.inboundOutboundDate}
              placeholder={labels.dateRangePlaceholderText}
              startIcon={CalendarTodayOutlined}
              minDate={new Date()}
              filterStyle={{
                fontWeight: "500 !important",
                color: "#2773FF !important",
                fontSize: "12px !important"
              }}
            />
          ) : (
            <DateInput
              mandatoryField
              control={control}
              name={SearchFormKeys.PickUpDate}
              label={filter_labels.pickupDate}
              placeholder={labels.pickUpDatePlaceholderText}
              startIcon={CalendarTodayOutlined}
              minDate={new Date()}
              filterStyle={{
                fontWeight: "500 !important",
                color: "#F9A90E !important",
                fontSize: "12px !important"
              }}
            />
          )}
        </Grid>
        {service?.warehouse && (
          <Grid item xs={12} md={service?.warehouse ? 2 : 2.5}>
            <DropdownStorageAndQuantity
              control={control}
              data={data}
              isFetching={isFetching}
              service={service}
              watch={watch}
              defaultValues={
                Boolean(FORM_DATA)
                  ? `${FORM_DATA[SearchFormKeys.StorageType] || ""} | ${
                      FORM_DATA[SearchFormKeys.pallets] || ""
                    }`
                  : null
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={service?.warehouse ? 1.5 : 2}>
          <DropDown
            availUnselect
            name={SearchFormKeys.MaterialDetails}
            control={control}
            placeholder={labels.materialDetailsPlaceholderText}
            unSelectText={labels.materialDetailsPlaceholderText}
            label={filter_labels.materialCategory}
            options={data?.materialCategories}
            isLoading={isFetching}
            filterStyle={{
              fontWeight: "500 !important",
              color: service?.warehouse
                ? "#2773FF !important"
                : "#F9A90E !important",
              fontSize: "12px !important"
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DropdownServiceAndWeight
            control={control}
            data={data}
            formType={formType}
            service={service}
            watch={watch}
            defaultValues={
              Boolean(FORM_DATA) &&
              Boolean(FORM_DATA[SearchFormKeys.AdditionalFeature]) &&
              Boolean(FORM_DATA[SearchFormKeys.totalWeight])
                ? `${FORM_DATA[SearchFormKeys.AdditionalFeature] || ""} | ${
                    FORM_DATA[SearchFormKeys.totalWeight] || 0
                  } Ton`
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="soft"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            sx={{ height: 48 }}
          >
            {labels.apply}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Filters;
