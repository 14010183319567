import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  allocation_labels,
  purchase_labels,
  salesOrder_labels
} from "../../constants";

import CustomTable from "../../shared/customTable";
import { CustomTypo, SubtotalTypo } from "../../purchase/pages/Vendors";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import appRoutes from "../../constants/appRoutes";
import { SalesOrders_filterKeys } from "../../constants/formKeys";
import {
  currencyFormatter,
  currencyOnlyFormatter,
  dropdownOptions,
  stringCapitalization
} from "../../shared/utils";
import { useForm } from "react-hook-form";
import Filter from "../../purchase/components/Filter";
import { AddOutlined, Search, TuneOutlined } from "@mui/icons-material";
import Ledger from "../../assets/Ledger.png";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import { useDrawer } from "../../shared/customSideDrawer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCustomCell } from "../../purchase/pages/PurchaseOrders";
import { useInView } from "react-intersection-observer";
import { useFetchSalesOrders } from "../../query-hooks/ClientSales/SalesOrder/useFetchSalesOrders";
import moment from "moment";
import { getDocumentsByType } from "../../purchase/pages/StockTransferOrders";
import SOTransactions from "./SOTransactions";
import { enqueueSnackbar } from "notistack";
import { useFetchSOFilters } from "../../query-hooks/ClientSales/SalesOrder/useFetchSOFilters";
import { useFetchSOSubtotal } from "../../query-hooks/ClientSales/SalesOrder/useFetchSOSubtotal";
import DropDown from "../../shared/formElements/DropDown";
import ManageSalesRep from "../components/Sales/ManageSalesRep";
import { MobileSalesOrders } from "../components/Sales/MobileSalesOrders";

const getColumns = (onButtonClick) => {
  return [
    {
      Header: "Doc. No.",
      accessor: "docNo",
      Cell: ({ row, value }) => {
        return getCustomCell(
          value,
          row.original.orderDate,
          `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${row.original.orderId}/salesOrder`
        );
      },
      show: false
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Status",
      accessor: "orderProgress",
      width: 200,
      Cell: ({ row, value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "MRR/ GP",
      accessor: "mrr",
      Cell: ({ row, value }) => {
        return value && getCustomCell(value[0], value[1], value[2], value[3]);
      }
    },
    {
      Header: "INV/ Credit Note",
      accessor: "inv",
      width: 200,
      Cell: ({ row, value }) => {
        return value && getCustomCell(value[0], value[1], ``, value[3]);
      }
    },
    {
      Header: "Invoice Value",
      accessor: "invoiceValue"
    },
    {
      Header: "Amt Received",
      accessor: "amountReceived"
    },
    {
      Header: "Balance",
      accessor: "balance"
    },
    {
      Header: "Avg. Due",
      accessor: "avgDue",
      Cell: ({ row, value }) => {
        return (
          row.original.orderProgress !== "Draft" &&
          row.original.orderProgress !== "Placed" && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Typography>{value}</Typography>
              <CustomTooltip title={"Ledger"} placement="top">
                <IconButton onClick={() => onButtonClick(row.original.orderId)}>
                  <img
                    src={Ledger}
                    className="pull-right cursor-field"
                    alt={"value"}
                  />
                </IconButton>
              </CustomTooltip>
            </Box>
          )
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row, index) => ({
    orderId: row?._id,
    docNo: row?.code ?? "SO-Draft",
    orderDate: moment(row?.orderDate).format("DD-MM-YYYY"),
    amount: currencyOnlyFormatter(parseFloat(row?.totalAmount).toFixed(2)),
    orderProgress: row?.status?.name,
    orderTableType: "Sale",
    mrr:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter(
              (obj) => obj?.docType?.name === "GatePass"
            ) ?? [],
            "Sale"
          )
        : "",
    inv:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter(
              (obj) => obj?.docType?.name === "SoInvoice"
            ) ?? []
          )
        : "",
    invoiceValue: row?.totalInvoiceAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalInvoiceAmount).toFixed(2))
      : "",
    amountReceived: row?.totalPaidAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalPaidAmount).toFixed(2))
      : "",
    balance:
      row?.totalInvoiceAmount && row?.totalPaidAmount
        ? currencyOnlyFormatter(
            parseFloat(
              +row?.totalInvoiceAmount - +row?.totalPaidAmount
            ).toFixed(2)
          )
        : "",
    avgDue: row?.avgDue ?? "",
    ...{
      ...(row?.saleReturns?.length > 0
        ? {
            subRow: row.saleReturns.map((sr, index) => {
              const getDoc =
                sr?.documents?.length > 0
                  ? getDocumentsByType(
                      sr?.documents?.filter(
                        (obj) => obj?.docType?.name === "Mrr"
                      ) ?? [],
                      "Sale"
                    )
                  : "";
              const getCNDoc =
                sr?.documents?.length > 0
                  ? getDocumentsByType(
                      sr?.documents?.filter(
                        (obj) => obj?.docType?.name === "CreditNote"
                      ) ?? []
                    )
                  : "";
              return {
                returnId: sr?._id,
                returnCode: sr?.code ?? "SR-Draft",
                returnOrderDate: moment(sr?.orderDate).format("DD-MM-YYYY"),
                returnOrderStatus: sr?.status?.name,
                docNo: getCustomCell(
                  sr?.code ?? "SR-Draft",
                  moment(sr?.orderDate).format("DD-MM-YYYY"),
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${row?._id}/salesReturns/${sr?._id}`
                ),
                amount: currencyOnlyFormatter(
                  parseFloat(sr?.totalAmount).toFixed(2)
                ),
                orderProgress: (
                  <StyledChip label={stringCapitalization(sr?.status?.name)} />
                ),
                mrr: getDoc
                  ? getCustomCell(getDoc[0], getDoc[1], getDoc[2], getDoc[3])
                  : "",
                inv: getCNDoc
                  ? getCustomCell(
                      getCNDoc[0],
                      getCNDoc[1],
                      getCNDoc[2],
                      getCNDoc[3]
                    )
                  : "",
                invoiceValue: sr?.totalNoteAmount ? (
                  <Typography fontSize={14} color={"#F05454"}>
                    {`(${currencyOnlyFormatter(
                      parseFloat(sr?.totalNoteAmount).toFixed(2)
                    )})`}
                  </Typography>
                ) : (
                  ""
                ),
                amountReceived: "",
                balance: "",
                avgDue: ""
              };
            })
          }
        : {})
    }
  }));
};

const SalesOrders = () => {
  const [params, setParams] = useState();
  const { control, reset, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { showDrawer, hideDrawer } = useDrawer();

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchSOFilters();

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchSOSubtotal(params);

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchSalesOrders(params);

  const onButtonClick = useCallback(
    (orderId) => {
      showDrawer({
        component: <SOTransactions hideDrawer={hideDrawer} orderId={orderId} />,
        closeIcon: false
      });
    },
    [hideDrawer, showDrawer]
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const saleTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(onButtonClick)}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.saleOrders)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileSalesOrders}
      />
    ),
    [data?.pages, isFetched, onButtonClick]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["warehouse"] !== ""
          ? {
              [`filters[warehouse]`]: data["warehouse"]
            }
          : {})
      },
      ...{
        ...(data["customer"] !== ""
          ? {
              [`filters[customer]`]: data["customer"]
            }
          : {})
      },
      ...{
        ...(data["status"] !== ""
          ? {
              [`filters[status]`]: data["status"]
            }
          : {})
      },
      ...{
        ...(data?.date[0] !== null
          ? {
              [`filters[fromDate]`]: moment(data?.date[0]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[1] !== null
          ? {
              [`filters[toDate]`]: moment(data?.date[1]).format("YYYY-MM-DD")
            }
          : {})
      }
    };
    setParams(filterData);
    hideDrawer();
  };

  const onClear = () => {
    reset({
      warehouse: "",
      customer: "",
      status: "",
      date: ""
    });
    setParams(null);
  };

  const FilterComponent = (
    <Box>
      <Filter
        control={control}
        onApply={handleSubmit(onViewClick)}
        onClear={onClear}
        isFetching={isFilterFetching}
        isLoading={isFetching}
        filters={[
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.warehouse,
            label: salesOrder_labels.warehouse,
            unSelectText: "Select Warehouse",
            options: dropdownOptions(filterData?.warehouses, "_id", "code"),
            filterCount: filterData?.warehouses?.length
          },
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.customer,
            label: salesOrder_labels.customer,
            unSelectText: "Select Customer",
            allowSearch: true,
            options: dropdownOptions(
              filterData?.customers,
              "_id",
              "companyName"
            ),
            filterCount: filterData?.customers?.length
          },
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.status,
            label: salesOrder_labels.status,
            unSelectText: "Select Status",
            options: filterData?.statuses,
            filterCount: filterData?.statuses?.length
          },
          {
            type: "DateInput",
            name: SalesOrders_filterKeys.date,
            label: salesOrder_labels.date,
            selectsRange: true
          }
        ]}
      />
    </Box>
  );

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid
          item
          xs={12}
          textAlign={"end"}
          container
          columnSpacing={4}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() => {
                showDrawer({
                  component: <ManageSalesRep hideDrawer={hideDrawer} />,
                  closeIcon: false
                });
              }}
            >
              {allocation_labels.ManageSalesRep}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.createOrder}`
                )
              }
            >
              <AddOutlined />
              {salesOrder_labels.createOrder}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {FilterComponent}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "0.6px solid #2773FF",
              borderRadius: 2,
              padding: 3,
              display: "flex",
              gap: 3,
              justifyContent: "space-between"
            }}
          >
            {isFetchingSubtotals ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <Box>
                  <CustomTooltip title={"Ledger"} placement="top">
                    <IconButton>
                      <img
                        src={Ledger}
                        className="pull-right cursor-field"
                        alt={"value"}
                      />
                    </IconButton>
                  </CustomTooltip>
                </Box>
                <Box display={"flex"} gap={3} alignItems={"center"}>
                  <CustomTypo color="primary">Subtotal :</CustomTypo>
                  {SubtotalTypo(
                    subTotalAmount?.subTotals?.totalInvoiceAmount
                      ? currencyFormatter(
                          parseFloat(
                            subTotalAmount?.subTotals?.totalInvoiceAmount
                          ).toFixed(2)
                        )
                      : "--",
                    "Invoice Value"
                  )}
                  {SubtotalTypo(
                    subTotalAmount?.subTotals?.totalPaidAmount
                      ? currencyFormatter(
                          parseFloat(
                            subTotalAmount?.subTotals?.totalPaidAmount
                          ).toFixed(2)
                        )
                      : "--",
                    "Amt Received"
                  )}
                  {SubtotalTypo(
                    !Boolean(subTotalAmount?.subTotals?.totalPaidAmount)
                      ? subTotalAmount?.subTotals?.totalInvoiceAmount
                        ? currencyOnlyFormatter(
                            parseFloat(
                              +subTotalAmount?.subTotals?.totalInvoiceAmount
                            ).toFixed(2)
                          )
                        : "--"
                      : currencyOnlyFormatter(
                          parseFloat(
                            +subTotalAmount?.subTotals?.totalInvoiceAmount -
                              +subTotalAmount?.subTotals?.totalPaidAmount
                          ).toFixed(2)
                        ),
                    "Balance"
                  )}
                  {SubtotalTypo(
                    subTotalAmount?.subTotals?.avgDueDays ?? "--",
                    "Avg. Due"
                  )}
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <Button
            variant="link"
            disableRipple
            sx={{ fontWeight: 700 }}
            onClick={() => {
              showDrawer({
                component: <ManageSalesRep hideDrawer={hideDrawer} />,
                height: "fit-content",
                title: "Manage Salesman",
                sx: { color: "#2773FF", fontWeight: 600 },
                closeIcon: true
              });
            }}
          >
            {allocation_labels.ManageSalesRep}
          </Button>
          <Button
            variant="link"
            disableRipple
            sx={{ fontWeight: 700 }}
            onClick={() =>
              navigate(
                `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.createOrder}`
              )
            }
          >
            {purchase_labels.createOrderMobileButton}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <DropDown
            control={control}
            name={"SO_id"}
            placeholder={"Search Order"}
            startIcon={Search}
            options={[]}
            allowSearch
            availUnselect
            unSelectText="Select Order"
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"right"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{saleTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default SalesOrders;
