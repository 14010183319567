import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Skeleton,
  Typography,
  styled
} from "@mui/material";

import { AppContext } from "../../shared/context/auth-context";
import { InfoOutlined } from "@mui/icons-material";
import appRoutes from "../../constants/appRoutes";
import { currencyFormatter } from "../../shared/utils";
import { labels } from "../../constants";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/hooks/useAuth";
import { StorageKeys } from "../../constants/storage-keys";
import Storage from "../../shared/utils/Storage";

const StyledCard = styled(Card)(({ theme }) => ({
  border: "0.4px solid #B7BEC7",
  borderRadius: 8,
  padding: "16px 12px",
  cursor: "pointer",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 148,
  width: "100%",
  borderRadius: 8,
  marginBottom: "16px"
}));

const StyledPrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const StyledSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 400
}));

const ListingCard = ({
  service,
  onSendRfqClick,
  onAddToCartClick,
  sendRfqList,
  monthlyRental,
  rateUnit,
  addonFees,
  totalAmount,
  title,
  city,
  state,
  country,
  id,
  tranName,
  loadCap,
  heroImage
}) => {
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);
  const { currentUser } = useAuth();
  const auth = useContext(AppContext);
  const navigate = useNavigate();

  const handleButtonAction = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
    action === "rfq" ? onSendRfqClick() : onAddToCartClick();
  };

  return (
    <StyledCard
      elevation={0}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (currentUser && Boolean(searchFormParams)) {
          navigate(
            appRoutes.explore.serviceDetailsRedirection + `${service}/${id}`
          );
          auth.setCrumbStack(
            appRoutes.explore.serviceDetailsRedirection + `${service}/${id}`,
            title
          );
          window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth"
          });
        }
      }}
    >
      <CardContent>
        {heroImage ? (
          <StyledCardMedia image={heroImage} title={title} />
        ) : (
          <Skeleton
            variant="rectangular"
            height={148}
            animation={false}
            sx={{ borderRadius: 2 }}
          />
        )}
        <Grid marginTop="24px" container display={"flex"} flexWrap={"wrap"}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={1}
          >
            <Box>
              <StyledPrimaryText
                fontSize={16}
                lineHeight="19.36px"
                fontWeight={600}
                letterSpacing="0.005em"
                marginBottom="4px"
              >
                {title}
              </StyledPrimaryText>
              <StyledSecondaryText
                lineHeight="18px"
                whiteSpace={"nowrap"}
              >{`${city}, ${state}, ${country}`}</StyledSecondaryText>
            </Box>
            {tranName && loadCap ? (
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                flexWrap={"wrap"}
              >
                <StyledSecondaryText
                  sx={{ textAlign: "start", whiteSpace: "nowrap" }}
                >
                  {tranName}
                </StyledSecondaryText>
                <StyledSecondaryText px={0.5}>{"|"}</StyledSecondaryText>
                <StyledSecondaryText>{loadCap}</StyledSecondaryText>
              </Box>
            ) : null}
          </Grid>
        </Grid>
        <Box marginTop="24px" display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column" marginRight="12px">
            <StyledPrimaryText
              fontSize={12}
              fontWeight={500}
              marginBottom="4px"
            >
              {tranName && loadCap
                ? `${currencyFormatter(monthlyRental)}`
                : `${currencyFormatter(monthlyRental)}/${rateUnit}`}
            </StyledPrimaryText>
            <StyledSecondaryText>
              {tranName && loadCap ? labels.pricekm : labels.monthlyRentalText}
            </StyledSecondaryText>
          </Box>
          <Box display="flex" flexDirection="column" marginRight="12px">
            <Box display="flex" flexDirection="column">
              <StyledPrimaryText
                fontSize={12}
                fontWeight={500}
                marginBottom="4px"
              >
                {`${currencyFormatter(Math.floor(addonFees))}`}
              </StyledPrimaryText>
              <StyledSecondaryText>{labels.addonFeesText}</StyledSecondaryText>
            </Box>
          </Box>
          <Divider orientation="vertical" sx={{ height: "auto", mr: "12px" }} />
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <StyledPrimaryText
                fontSize={14}
                fontWeight={700}
                marginBottom="4px"
                lineHeight="16.94px"
              >
                {`${currencyFormatter(Math.floor(totalAmount))}`}
              </StyledPrimaryText>
              <InfoOutlined
                fontSize="10.5px"
                sx={{
                  color: "#607088",
                  ml: "3.75px"
                }}
              />
            </Box>
            <StyledSecondaryText>{labels.totalAmountText}</StyledSecondaryText>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box display={"flex"} gap={"20px"}>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: 13,
              textTransform: "uppercase",
              letterSpacing: "0.39px"
            }}
            onClick={(e) => handleButtonAction(e, "rfq")}
          >
            {labels.sendRFQButtonSingleLabelText}
          </Button>
          {sendRfqList?.length === 0 && (
            <Button
              variant="link"
              disableRipple
              sx={{
                fontWeight: 600,
                fontSize: 13,
                textTransform: "uppercase",
                letterSpacing: "0.39px"
              }}
              onClick={(e) => handleButtonAction(e, "cart")}
            >
              {labels.addToCartButtonLabelText}
            </Button>
          )}
        </Box>
      </CardActions>
    </StyledCard>
  );
};

export default ListingCard;
