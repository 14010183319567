import { Box, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { StyledHeaderText } from "./SaaS";
import { StyledText } from "./Marketplace";
import InventoryDashboard from "../../assets/Inventory_management.png";
import SKULedger from "../../assets/SKU_ledger.png";
import InventoryPerformance from "../../assets/SKU_Performance.png";

const InventoryManagement = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      {/* Mobile view */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          padding: "30px 25px 0px 25px",
          gap: 2
        }}
      >
        <StyledHeaderText color={"#F9A90E"} component="h1" isMobile={true}>
          {`${labels.inventoryHeaderText1} `}
          <span style={{ color: "#2773FF" }}>
            {labels.inventoryHeaderText2}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              src={InventoryDashboard}
              alt={"Inventory Dashboard"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <StyledText isMobile>
              <span style={{ color: "#2773FF", fontWeight: 700 }}>Simple</span>{" "}
              Features to suit{" "}
              <span style={{ color: "#F9A90E", fontWeight: 700 }}>
                Small Businesses
              </span>
            </StyledText>
            <StyledText
              component="div"
              isMobile
              sx={{ fontSize: "22px", fontWeight: 400 }}
            >
              <ol>
                <li>
                  Upload 1000+ SKUs at once through{" "}
                  <span style={{ color: "#2773FF" }}>Excel</span>.
                </li>
                <li>
                  Filter Inventory levels{" "}
                  <span style={{ color: "#2773FF" }}>warehouse</span> wise and{" "}
                  <span style={{ color: "#0F2744" }}>brand wise</span>.
                </li>
                <li>
                  Item pictures provide better{" "}
                  <span style={{ color: "#2773FF" }}>appearance</span>.
                </li>
                <li>
                  SKU level{" "}
                  <span style={{ color: "#0F2744" }}>stock count</span> and{" "}
                  <span style={{ color: "#2773FF" }}>buffer level</span>{" "}
                  monitoring.
                </li>
                <li>
                  Track categories and other{" "}
                  <span style={{ color: "#2773FF" }}>attributes</span> of every
                  SKU.
                </li>
              </ol>
            </StyledText>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={1}
          gap={1}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            sx={{
              color: "#2773FF",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "30px",
              letterSpacing: "-0.01em",
              textAlign: "justified"
            }}
          >
            {labels.skuWiseLedger}
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box
              display={"flex"}
              sx={{
                width: "100%",
                position: "relative",
                overflow: "hidden"
              }}
            >
              <img
                src={SKULedger}
                alt={"Item Ledger"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain" // Ensures the entire image fits within the box
                }}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              justifyContent={"center"}
            >
              <StyledText isMobile>
                Review SKU wise<span style={{ color: "#2773FF" }}> ledger</span>{" "}
                between any specific dates.
              </StyledText>
              <StyledText isMobile>
                Prepare enough stock levels based on the quantity
                <span style={{ color: "#2773FF" }}>
                  {" "}
                  queued in Sales Orders.
                </span>{" "}
              </StyledText>
              <StyledText isMobile>
                Track your inventory overall and
                <span style={{ color: "#2773FF" }}> warehouse level.</span>{" "}
              </StyledText>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={1}
          gap={1}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            sx={{
              color: "#2773FF",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "30px",
              letterSpacing: "-0.01em",
              textAlign: "justified"
            }}
          >
            {labels.inventoryPerformance}
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box
              display={"flex"}
              sx={{
                width: "100%",
                position: "relative",
                overflow: "hidden"
              }}
            >
              <img
                src={InventoryPerformance}
                alt={"SKU Performance"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain" // Ensures the entire image fits within the box
                }}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              justifyContent={"center"}
            >
              <StyledText isMobile>
                Calculates SKU level
                <span style={{ color: "#2773FF" }}> Gross Margins.</span>{" "}
              </StyledText>
              <StyledText isMobile>
                Maintain Inventory in
                <span style={{ color: "#2773FF" }}> FIFO</span>{" "}
                <span style={{ color: "#2773FF" }}> (First In First Out)</span>{" "}
                cost lots.
              </StyledText>
              <StyledText isMobile>
                Get your inventory reports with a detailed
                <span style={{ color: "#2773FF" }}>
                  {" "}
                  inwards and outwards receipts.
                </span>
              </StyledText>
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      {/* Web view */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          padding: "30px 70px 0px 80px",
          gap: 4
        }}
      >
        <StyledHeaderText color={"#F9A90E"} component="h1">
          {`${labels.inventoryHeaderText1} `}
          <span style={{ color: "#2773FF" }}>
            {labels.inventoryHeaderText2}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} gap={2}>
          <Box display={"flex"} flexDirection={"column"} width={"30%"}>
            <StyledText>
              <span style={{ color: "#2773FF", fontWeight: 700 }}>Simple</span>{" "}
              Features to suit{" "}
              <span style={{ color: "#F9A90E", fontWeight: 700 }}>
                Small Businesses
              </span>
            </StyledText>
            <StyledText
              component="div"
              sx={{ fontSize: "22px", fontWeight: 400 }}
            >
              <ol>
                <li>
                  Upload 1000+ SKUs at once through{" "}
                  <span style={{ color: "#2773FF" }}>Excel</span>.
                </li>
                <li>
                  Filter Inventory levels{" "}
                  <span style={{ color: "#2773FF" }}>warehouse</span> wise and{" "}
                  <span style={{ color: "#0F2744" }}>brand wise</span>.
                </li>
                <li>
                  Item pictures provide better{" "}
                  <span style={{ color: "#2773FF" }}>appearance</span>.
                </li>
                <li>
                  SKU level{" "}
                  <span style={{ color: "#0F2744" }}>stock count</span> and{" "}
                  <span style={{ color: "#2773FF" }}>buffer level</span>{" "}
                  monitoring.
                </li>
                <li>
                  Track categories and other{" "}
                  <span style={{ color: "#2773FF" }}>attributes</span> of every
                  SKU.
                </li>
              </ol>
            </StyledText>
          </Box>
          <Box
            display={"flex"}
            sx={{
              width: "70%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              src={InventoryDashboard}
              alt={"Inventory Dashboard"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={2}
          gap={4}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            sx={{
              color: "#2773FF",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "36px",
              letterSpacing: "-0.01em",
              textAlign: "justified"
            }}
          >
            {labels.skuWiseLedger}
          </Typography>
          <Box display={"flex"} gap={2}>
            <Box
              display={"flex"}
              sx={{
                width: "70%",
                position: "relative",
                overflow: "hidden"
              }}
            >
              <img
                src={SKULedger}
                alt={"Item Ledger"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain" // Ensures the entire image fits within the box
                }}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"30%"}
              gap={6}
              justifyContent={"center"}
            >
              <StyledText>
                Review SKU wise<span style={{ color: "#2773FF" }}> ledger</span>{" "}
                between any specific dates.
              </StyledText>
              <StyledText>
                Prepare enough stock levels based on the quantity
                <span style={{ color: "#2773FF" }}>
                  {" "}
                  queued in Sales Orders.
                </span>{" "}
              </StyledText>
              <StyledText>
                Track your inventory overall and
                <span style={{ color: "#2773FF" }}> warehouse level.</span>{" "}
              </StyledText>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={2}
          gap={4}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            sx={{
              color: "#2773FF",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "36px",
              letterSpacing: "-0.01em",
              textAlign: "justified"
            }}
          >
            {labels.inventoryPerformance}
          </Typography>
          <Box display={"flex"} gap={2}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"30%"}
              gap={6}
              justifyContent={"center"}
            >
              <StyledText>
                Calculates SKU level
                <span style={{ color: "#2773FF" }}> Gross Margins.</span>{" "}
              </StyledText>
              <StyledText>
                Maintain Inventory in
                <span style={{ color: "#2773FF" }}> FIFO</span>{" "}
                <span style={{ color: "#2773FF" }}> (First In First Out)</span>{" "}
                cost lots.
              </StyledText>
              <StyledText>
                Get your inventory reports with a detailed
                <span style={{ color: "#2773FF" }}>
                  {" "}
                  inwards and outwards receipts.
                </span>
              </StyledText>
            </Box>
            <Box
              display={"flex"}
              sx={{
                width: "70%",
                position: "relative",
                overflow: "hidden"
              }}
            >
              <img
                src={InventoryPerformance}
                alt={"SKU Performance"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain" // Ensures the entire image fits within the box
                }}
              />
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};
export default InventoryManagement;
