import { Box, Button, Grid, Typography } from "@mui/material";

import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import CreditImage from "../../assets/Credit.jpg";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../constants/appRoutes";

export const CreditSection = () => {
  const navigate = useNavigate();
  return (
    <Box
      id="credit-section"
      sx={{
        display: {
          xs: "flex",
          md: "flex"
        },
        flexDirection: "column",
        textAlign: "center",
        background: "#F7F8FA",
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: 0,
          md: "5px 65px"
        },
        gap: { xs: 0, md: 2 }
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        sx={{
          position: "relative"
        }}
      >
        <Box>
          <SectionHeader
            title={home_labels.WarexCredit}
            props={{ fontWeight: { xs: 400, md: 700 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: { xs: "10px", md: 0 },
            paddingTop: { xs: "10px", md: 0 }
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "80px", md: "150px" },
              padding: { xs: "0px" },
              height: { xs: "30px", md: "60px" },
              fontSize: { xs: "10px", md: "17px" },
              lineHeight: { xs: "12px", md: "20px" },
              borderRadius: "4px",
              border: "1px 0px 0px 0px",
              opacity: "0.99px"
            }}
            onClick={() => navigate(appRoutes.credit)}
          >
            {"Know More"}
          </Button>
        </Box>
      </Box>
      <Grid
        container
        columnSpacing={{ xs: 0, md: 4 }}
        sx={{ padding: { xs: "", md: "30px" } }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <img
            src={CreditImage}
            alt={home_labels.creditInvoice}
            width={"100%"}
            height={"auto"}
            style={{
              objectFit: "contain" // Ensures the entire image fits within the box
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            sx={{ gap: { xs: 0, md: 8 }, padding: { xs: "0 20px", md: 0 } }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: 14,
                  md: 28
                },
                fontWeight: 500,
                lineHeight: {
                  xs: "17px",
                  md: "34px"
                },
                letterSpacing: "0.2%",
                mt: {
                  xs: "20px",
                  md: "10px"
                },
                maxWidth: {
                  xs: "100%",
                  md: 444
                },
                textAlign: "justify"
              }}
              color={"text.secondary"}
            >
              SMEs can now manage their <strong>working capital</strong> better
              by availing Credit facility through Warex's partnered NBFCs.
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: 14,
                  md: 28
                },
                fontWeight: 500,
                lineHeight: {
                  xs: "17px",
                  md: "34px"
                },
                letterSpacing: "0.2%",
                mt: {
                  xs: "20px",
                  md: 0
                },
                maxWidth: {
                  xs: "100%",
                  md: 444
                },
                mb: {
                  xs: "20px",
                  md: 0
                },
                textAlign: "justify"
              }}
              color={"text.secondary"}
            >
              Avail <strong>up to 95%</strong> of the invoice amounts upfront.
              Manage your customers and Vendors without hassles.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
