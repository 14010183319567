import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { isMobile, isTablet } from "react-device-detect";
import { loadGoogleMapsScript } from '../utils/loadGoogleMapsScript';

const containerStyle = {
  width: "auto",
  height: "calc(100vh - 184px)",
  borderRadius: isMobile || isTablet ? 0 : "8px 8px 0 0",
  border: "0.4px solid #E6EAEE",
  borderBottom: 0
};

const GoogleMapContainer = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  // const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      setIsLoaded(true);
    })
  }, []);

//   if (loadError) return <>{"Error loading maps"}</>;
  if (!isLoaded) return <>{"Loading Maps"}</>;

  return (
    <GoogleMap
      center={{
        lat: data?.[0]?.warehouses?.[0]?.address.location.coordinates[0],
        lng: data?.[0]?.warehouses?.[0]?.address.location.coordinates[1]
      }}
      zoom={5}
      mapContainerStyle={containerStyle}
      options={{
        mapTypeControl: false,
        fullscreenControl: false
      }}
    >
      {data?.map((page) =>
        page?.warehouses?.map((item, index) => (
          <MarkerF
            title={item.code}
            key={index}
            position={{
              lat: item.address.location.coordinates[0],
              lng: item.address.location.coordinates[1]
            }}
          />
        ))
      )}
    </GoogleMap>
  );
};

export default GoogleMapContainer;
