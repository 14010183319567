import {
  Avatar,
  Badge,
  Box,
  List,
  ThemeProvider,
  Toolbar,
  Typography
} from "@mui/material";
import { StorageKeys } from "../../constants/storage-keys";
import { StyledDrawer, drawerTheme } from "../theme/drawerTheme";
// import { isMobile, isTablet } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";

import { CustomTooltip } from "../theme/globalTheme";
import { ReactComponent as DashboardIcon } from "../../assets/icons/Dashboard.svg";
import { ReactComponent as FinanceIcon } from "../../assets/icons/finance.svg";
import IMAGE from "../../assets/banner.png";
import { ReactComponent as InquiryIcon } from "../../assets/icons/Enquiry.svg";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import { NavLinkList } from "./NavLinkList";
import { ReactComponent as OrderIcon } from "../../assets/icons/Order.svg";
import { ReactComponent as PurchaseIcon } from "../../assets/icons/shopping-catalog.svg";
import { ReactComponent as SalesIcon } from "../../assets/icons/sales.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import Storage from "../utils/Storage";
import { ReactComponent as WarehouseIcon } from "../../assets/icons/warehouse.svg";
import WhiteColorEdit from "../../assets/icons/WhiteColorEdit.svg";
import appRoutes from "../../constants/appRoutes";
import warexLogo from "../../assets/Warex-Menu-logo.png";
import { AppContext } from "../context/auth-context";
import { useContext } from "react";
import moment from "moment/moment";

// const isMobileDevice = isMobile || isTablet;

// const localFormType = Storage.getItem(StorageKeys.SEARCH_FORM_TYPE);
const localFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

export const NAV_LINK = [
  { to: appRoutes.dashboard, name: "Dashboard", icon: DashboardIcon },
  {
    to: localFormParams ? appRoutes.explore.listings : appRoutes.explore.main,
    name: "Explore",
    icon: SearchIcon
  },
  {
    to: appRoutes.inquiry.inquiryListView,
    name: "Inquiry",
    icon: InquiryIcon
  },
  {
    name: "Orders",
    icon: OrderIcon,
    from: appRoutes.orders.main,
    children: [
      {
        to: appRoutes.orders.myOrdersListView,
        name: "My orders"
      },
      {
        to: appRoutes.orders.invoicesDocsListView,
        name: "Invoices & docs"
      },
      {
        to: appRoutes.orders.retrieveListView,
        name: "Retrieve"
      }
    ]
  },
  {
    name: "Inventory",
    icon: InventoryIcon,
    from: appRoutes.inventory.main,
    children: [
      {
        to: appRoutes.inventory.sku,
        name: "SKUs"
      },
      {
        to: appRoutes.inventory.performance,
        name: "Performance"
      }
    ]
  },
  {
    name: "Warehouse",
    icon: WarehouseIcon,
    from: appRoutes.warehouse.main,
    children: [
      {
        to: appRoutes.warehouse.assets,
        name: "Assets"
      },
      {
        to: appRoutes.warehouse.register,
        name: "Register"
      }
    ]
  },
  {
    name: "Purchase",
    icon: PurchaseIcon,
    from: appRoutes.purchase.main,
    children: [
      {
        to: appRoutes.purchase.vendors,
        name: "Vendors"
      },
      {
        to: appRoutes.purchase.purchaseOrders,
        name: "Purchase Orders"
      }
    ]
  },
  {
    name: "Sale",
    icon: SalesIcon,
    from: appRoutes.sales.main,
    children: [
      {
        to: appRoutes.sales.customers,
        name: "Customers"
      },
      {
        to: appRoutes.sales.quotes,
        name: "Quotes"
      },
      {
        to: appRoutes.sales.salesOrders,
        name: "Sales Orders"
      },
      {
        to: appRoutes.sales.allocation,
        name: "Allocation"
      }
    ]
  },
  {
    name: "Finance",
    icon: FinanceIcon,
    from: appRoutes.finance.main,
    children: [
      {
        to: appRoutes.finance.invoices,
        name: "Invoices"
      },
      {
        to: appRoutes.finance.payments,
        name: "Payments"
      }
    ]
  },
  { to: appRoutes.settings, name: "Settings", icon: SettingsIcon }
];

export const PROFILE_NAV_LINK = [
  {
    to: appRoutes.profile.main,
    name: "Profile",
    icon: DashboardIcon
  }
  // {
  //   to: appRoutes.profile.changePassword,
  //   name: isMobileDevice ? "Password" : "Change password",
  //   icon: DashboardIcon
  // },
  // {
  //   to: appRoutes.profile.helpAndSupport,
  //   name: isMobileDevice ? "Help" : "Help & Support",
  //   icon: DashboardIcon
  // },
  // {
  //   to: appRoutes.profile.faqs,
  //   name: "FAQs",
  //   icon: DashboardIcon
  // },
  // {
  //   to: appRoutes.profile.tnc,
  //   name: isMobileDevice ? "T&C" : "Terms & Conditions",
  //   icon: DashboardIcon
  // }
];

export const filterNavLinks = (userRoles, ScModules, isActive) => {
  const marketPlace = ["Dashboard", "Explore", "Inquiry", "Orders"];
  const moduleNames = ScModules && ScModules?.map((module) => module.name);
  return userRoles === "CustomerAdmin" && isActive
    ? NAV_LINK
    : userRoles === "CustomerAdmin" && !isActive
    ? NAV_LINK.filter(
        (item) => item.name === "Dashboard" || marketPlace.includes(item.name)
      )
    : userRoles === "ClientSubUser" && isActive
    ? NAV_LINK.filter(
        (item) => item.name === "Dashboard" || moduleNames.includes(item.name)
      )
    : userRoles === "ClientSubUser" && !isActive
    ? NAV_LINK.filter((item) => item.name === "Dashboard")
    : [];
};

const ProfileImageAndLabel = ({ profileData }) => {
  return (
    <>
      <Box sx={{ alignSelf: "center", mt: 7, mb: 2 }}>
        <Badge
          sx={{ border: "5px solid #FFFFFF", borderRadius: "50%" }}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <CustomTooltip title={"Edit profile image"} placement="top">
              <img
                src={WhiteColorEdit}
                style={{ cursor: "pointer" }}
                className="pull-right cursor-field"
                alt={"Edit profile"}
              />
            </CustomTooltip>
          }
        >
          <Avatar
            alt="Profile Image"
            src={profileData?.user?.client?.logo?.thumbUrl || IMAGE}
            sx={{ width: 80, height: 80 }}
          />
        </Badge>
      </Box>
      <Box sx={{ display: "block", alignSelf: "center", mb: 2 }}>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "24.2px"
          }}
        >
          {`${profileData?.user?.firstName ?? ""} ${
            profileData?.user?.lastName ?? ""
          }`}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px",
            mt: 1
          }}
        >
          {`Member since ${moment(profileData?.user?.createdAt).format(
            "YYYY"
          )}`}
        </Typography>
      </Box>
    </>
  );
};

const SideDrawer = () => {
  const { profileData } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isProfileScreen =
    location.pathname.includes(appRoutes.profile.main) || false;
  return (
    <ThemeProvider theme={drawerTheme}>
      <StyledDrawer variant="permanent" anchor="left" role="navigation">
        <Toolbar>
          <Box
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img src={warexLogo} width={140} alt="Warex" height={35} />
          </Box>
        </Toolbar>
        {isProfileScreen && <ProfileImageAndLabel profileData={profileData} />}
        <List component="nav">
          <NavLinkList
            links={
              isProfileScreen
                ? PROFILE_NAV_LINK
                : filterNavLinks(
                    profileData?.user?.role,
                    profileData?.user?.allowedScModules,
                    Boolean(
                      profileData?.user?.client?.scModuleStatus?.name ===
                        "Active"
                    )
                  )
            }
            isProfileScreen={isProfileScreen}
          />
        </List>
      </StyledDrawer>
    </ThemeProvider>
  );
};

export default SideDrawer;
