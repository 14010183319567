import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography
} from "@mui/material";

import DateInput from "../../shared/formElements/DateInput";
import { InvoiceUploadKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import SkuImageUpload from "../../shared/formElements/SkuImageUpload";
import PDFICON from "../../assets/pdfFile.png";
import { handleDownload } from "../../warehouse/components/MaterialReceivedReport/MrrTable";
import { DocInfo } from "../../warehouse/components/MobileOrdersPage";
import { Verified } from "@mui/icons-material";
import { ImageUpload } from "../../shared/formElements/ImageUpload";

const InputFields = ({ control, Title, showSideLabel = false }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        container
        columnSpacing={1}
        display={"flex"}
        justifyContent={"center"}
      >
        {showSideLabel && (
          <Grid
            item
            xs={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"right"}
          >
            <Typography
              fontSize={14}
              lineHeight={"16.93px"}
              fontWeight={400}
            >{`${Title} ID :`}</Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextInput
            control={control}
            label={!showSideLabel && `${Title} ID`}
            placeholder={"Enter ID"}
            name={InvoiceUploadKeys.invoiceId}
            sx={{ maxWidth: "172px", borderRadius: { xs: "54px", md: "8px" } }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        columnSpacing={1}
        display={"flex"}
        justifyContent={"center"}
      >
        {showSideLabel && (
          <Grid
            item
            xs={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"right"}
          >
            <Typography
              fontSize={14}
              lineHeight={"16.93px"}
              fontWeight={400}
            >{`${Title} date :`}</Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <DateInput
            control={control}
            placeholder={"Enter date"}
            label={!showSideLabel && `${Title} date`}
            name={InvoiceUploadKeys.invoiceDate}
            minDate={"2000-01-01"}
            sx={{ maxWidth: "172px", borderRadius: { xs: "54px", md: "8px" } }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        columnSpacing={1}
        display={"flex"}
        justifyContent={"center"}
      >
        {showSideLabel && (
          <Grid
            item
            xs={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"right"}
          >
            <Typography
              fontSize={14}
              lineHeight={"16.93px"}
              fontWeight={400}
            >{`${Title} Value :`}</Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextInput
            control={control}
            label={!showSideLabel && `${Title} Value`}
            placeholder={`Enter ${Title} Value`}
            name={InvoiceUploadKeys.invoiceAmount}
            sx={{ maxWidth: "172px", borderRadius: { xs: "54px", md: "8px" } }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ManualInvoiceUpload = ({
  control,
  setValue,
  setShowManual,
  onSubmit,
  errors,
  InvInfo = "",
  Title
}) => {
  const UploadButtonGroup = (
    <>
      <Button
        variant="soft"
        fullWidth
        sx={{
          height: 48
        }}
        onClick={() => setShowManual(false)}
      >
        {`Skip and Raise ${Title}`}
      </Button>
      <Button
        fullWidth
        variant="contained"
        sx={{
          height: 48
        }}
        onClick={onSubmit}
      >
        Upload
      </Button>
    </>
  );

  return (
    <Grid
      container
      padding={2}
      columnSpacing={1}
      rowSpacing={4}
      sx={{ mb: { xs: 10, md: 0 } }}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        sx={{ justifyContent: { xs: "center", md: "left" } }}
      >
        <Typography fontWeight={600}>
          {Boolean(InvInfo) ? Title : `Upload ${Title}`}
        </Typography>
      </Grid>
      {/* Web view */}
      {Boolean(InvInfo) ? (
        <>
          <Grid
            item
            xs={6}
            sx={{ display: { xs: "none", md: "flex" }, background: "#F3F5F9" }}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"500px"}
          >
            {InvInfo?.file?.mimeType === "application/pdf" ? (
              <>
                <img
                  src={PDFICON}
                  alt={InvInfo?.file?.originalName}
                  style={{
                    width: "20%",
                    height: "20%"
                  }}
                />
                <Button variant="link" disableRipple>
                  {InvInfo?.file?.originalName}
                </Button>
              </>
            ) : (
              <img
                src={InvInfo?.file?.fileUrl}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain"
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
            container
            sx={{ display: { xs: "none", md: "flex" } }}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              alignItems={"center"}
            >
              <DocInfo label={`${Title} ID : `} value={InvInfo?.code} />
              <DocInfo label={`${Title} Date : `} value={InvInfo?.date} />
              <DocInfo label={`${Title} Value : `} value={InvInfo?.amount} />
              <DocInfo
                label={`Payement Terms : `}
                value={`${InvInfo?.advance}% Advance. Balance Due ${InvInfo?.due} days from receipt`}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"space-evenly"}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  height: 42,
                  width: {
                    xs: "100%",
                    md: 164
                  }
                }}
                onClick={() => handleDownload(InvInfo?.file?.fileUrl)}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={6}
            sx={{ display: { xs: "none", md: "flex" } }}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"500px"}
          >
            <SkuImageUpload
              control={control}
              name={InvoiceUploadKeys.manualInvoice}
              setValue={setValue}
              uploadButtonText={"Browse file"}
              invoiceType
            />
            <FormHelperText error>
              {errors.manualInvoice?.message}
            </FormHelperText>
          </Grid>
          <Grid
            item
            xs={6}
            container
            sx={{ display: { xs: "none", md: "flex" } }}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              alignItems={"center"}
            >
              <InputFields control={control} Title={Title} />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-evenly"}
              gap={4}
            >
              {UploadButtonGroup}
            </Grid>
          </Grid>
        </>
      )}
      {/* Mobile view */}
      {Boolean(InvInfo) ? (
        <Grid
          item
          xs={12}
          container
          sx={{ display: { xs: "flex", md: "none" } }}
          alignItems={"center"}
          rowSpacing={5}
        >
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            alignItems={"center"}
          >
            <DocInfo label={`${Title} ID : `} value={InvInfo?.code} />
            <DocInfo label={`${Title} Date : `} value={InvInfo?.date} />
            <DocInfo label={`${Title} Value : `} value={InvInfo?.amount} />
            <DocInfo
              label={`Payement Terms : `}
              value={`${InvInfo?.advance}% Advance. Balance Due ${InvInfo?.due} days from receipt`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Verified sx={{ color: "primary.main" }} />
            <Button
              variant="link"
              disableRipple
              sx={{ fontWeight: 600 }}
              onClick={() => handleDownload(InvInfo?.file?.fileUrl)}
            >
              {InvInfo?.code}
            </Button>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"space-evenly"}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                height: 42,
                width: {
                  xs: "100%",
                  md: 164
                }
              }}
              onClick={() => handleDownload(InvInfo?.file?.fileUrl)}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            container
            sx={{ display: { xs: "flex", md: "none" } }}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              alignItems={"center"}
            >
              <InputFields control={control} Title={Title} showSideLabel />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: "flex", md: "none" } }}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ImageUpload
              name={InvoiceUploadKeys.manualInvoice}
              control={control}
              setValue={setValue}
              compact={true}
              uploadButtonText={`Upload ${Title}`}
              acceptFileType=".jpg, .jpeg, .png, .pdf"
              showTextOnly
            />
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: "flex", md: "none" } }}
            flexDirection={"column"}
            gap={2}
            mt={10}
          >
            {UploadButtonGroup}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ManualInvoiceUpload;
