import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductFeatureItem from "./ProductFeatureItem";
import Explore from "../../assets/Explore.jpg";
import RFQ from "../../assets/RFQ.jpg";
import Quote from "../../assets/Quote.jpg";
import compareQuotes from "../../assets/compareQuotes.jpg";
import orderManagement from "../../assets/orderManagement.jpg";
import Ship from "../../assets/Ship.jpg";
import appRoutes from "../../constants/appRoutes";

const product = [
  {
    label: home_labels.Explore,
    subtext: home_labels.exploreSubtext,
    image: Explore,
    padding: "92px",
    id: "Explore"
  },
  {
    label: home_labels.RFQ,
    subtext: home_labels.rfqSubText,
    image: RFQ,
    padding: "92px",
    id: "RFQ"
  },
  {
    label: home_labels.Quote,
    subtext: home_labels.QuoteSubtext,
    image: Quote,
    padding: "13.75px",
    id: "Quote"
  },
  {
    label: home_labels.compareQuotes,
    subtext: home_labels.compareQuotesSubtext,
    image: compareQuotes,
    padding: "13.75px",
    id: "compareQuotes"
  },
  {
    label: home_labels.orderManagement,
    subtext: home_labels.orderManagementSubtext,
    image: orderManagement,
    padding: "13.75px",
    id: "orderManagement"
  },
  {
    label: home_labels.ship,
    subtext: home_labels.shipSubTextonly,
    image: Ship,
    padding: "13.75px",
    id: "Ship"
  }
];

export const MarketplaceSection = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const sliderRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      const entry = entries[0];
      setIsInView(entry.intersectionRatio >= 0.5);
    };

    const observerOptions = {
      threshold: [0.5]
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      if (isInView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [isInView]);

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current && isInView) {
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false, // Default to false
    autoplaySpeed: 2000
  };

  return (
    <Box
      id="market-place"
      ref={sectionRef}
      display="flex"
      flexDirection="column"
      textAlign="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        background: "#F7F8FA",
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "0 20px",
          md: "5px 65px"
        },
        gap: { xs: 0, md: 2 }
      }}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          position: "relative",
          justifyContent: { xs: "space-between", md: "center" },
          alignItems: "center"
        }}
      >
        <Box>
          <SectionHeader
            title={home_labels.WarexMarketplace}
            props={{ fontWeight: { xs: 400, md: 700 } }}
          />
        </Box>
        <Box
          sx={{
            position: { md: "absolute" },
            right: { md: 0 },
            paddingTop: { xs: "20px", md: 0 }
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "80px", md: "150px" },
              padding: { xs: "0px" },
              height: { xs: "30px", md: "60px" },
              fontSize: { xs: "10px", md: "17px" },
              lineHeight: { xs: "12px", md: "20px" },
              borderRadius: "4px",
              border: "1px 0px 0px 0px",
              opacity: "0.99px"
            }}
            onClick={() => navigate(appRoutes.marketplace)}
          >
            {"Know More"}
          </Button>
        </Box>
      </Box>
      <Slider ref={sliderRef} {...settings}>
        {product.map((item) => (
          <div key={item.label}>
            <Grid item xs={12} md={4} style={{ marginRight: "16px" }}>
              <ProductFeatureItem {...item} />
            </Grid>
          </div>
        ))}
      </Slider>
    </Box>
  );
};
