import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { purchase_labels, salesOrder_labels } from "../../constants";

import CustomTable from "../../shared/customTable";
import { CustomTypo, SubtotalTypo } from "./Vendors";
import Filter from "../components/Filter";
import { SalesOrders_filterKeys } from "../../constants/formKeys";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import appRoutes from "../../constants/appRoutes";
import {
  currencyFormatter,
  currencyOnlyFormatter,
  dropdownOptions,
  stringCapitalization
} from "../../shared/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDrawer } from "../../shared/customSideDrawer";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useFetchPurchaseOrders } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPurchaseOrders";
import moment from "moment";
import { getDocumentsByType } from "./StockTransferOrders";
import Ledger from "../../assets/Ledger.png";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import POTransactions from "./POTransactions";
import { useFetchPOFilters } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPOFilters";
import { enqueueSnackbar } from "notistack";
import { useFetchPOSubtotal } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPOSubtotal";
import { Search, TuneOutlined } from "@mui/icons-material";
import DropDown from "../../shared/formElements/DropDown";
import { MobilePurchaseOrders } from "../components/Purchase/MobilePurchaseOrders";

export const getCustomCell = (value, date = "", to = "", docCount = null) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
      <Box display={"flex"}>
        <Typography fontSize={14} color={"primary"} component={Link} to={to}>
          {value}
        </Typography>
        {Boolean(docCount) && (
          <Typography
            component="span"
            sx={{
              verticalAlign: "super",
              fontSize: "0.8em",
              color: "error.main"
            }}
          >
            {`+${docCount}`}
          </Typography>
        )}
      </Box>
      {date && <Typography fontSize={14}>{date}</Typography>}
    </Box>
  );
};

const getColumns = (action) => {
  return [
    {
      Header: "Doc. No.",
      accessor: "docNo",
      Cell: ({ row, value }) => {
        return getCustomCell(
          value,
          row.original.orderDate,
          `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${row.original.orderId}/purchaseOrder`
        );
      },
      show: false
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Status",
      accessor: "orderProgress",
      width: 200,
      Cell: ({ row, value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "MRR/ GP",
      accessor: "mrr",
      Cell: ({ row, value }) => {
        return value && getCustomCell(value[0], value[1], value[2], value[3]);
      }
    },
    {
      Header: "INV/ Debit Note",
      accessor: "inv",
      width: 200,
      Cell: ({ row, value }) => {
        return value && getCustomCell(value[0], value[1], ``, value[3]);
      }
    },
    {
      Header: "Invoice Value",
      accessor: "invoiceValue"
    },
    {
      Header: "Amt Paid",
      accessor: "amountPaid"
    },
    {
      Header: "Balance",
      accessor: "balance"
    },
    {
      Header: "Avg. Due",
      accessor: "avgDue",
      Cell: ({ row, value }) => {
        return (
          row.original.orderProgress !== "Draft" &&
          row.original.orderProgress !== "Placed" && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <Typography fontSize={14}>{value}</Typography>
              <CustomTooltip title={"Ledger"} placement="top">
                <IconButton onClick={() => action(row.original.orderId)}>
                  <img
                    src={Ledger}
                    className="pull-right cursor-field"
                    alt={"value"}
                  />
                </IconButton>
              </CustomTooltip>
            </Box>
          )
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row, index) => ({
    orderId: row?._id,
    docNo: row?.code ?? "PO-Draft",
    orderDate: moment(row?.orderDate).format("DD-MM-YYYY"),
    amount: currencyOnlyFormatter(parseFloat(row?.totalAmount).toFixed(2)),
    orderProgress: row?.status?.name,
    orderTableType: "Purchase",
    mrr:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter((obj) => obj?.docType?.name === "Mrr") ?? [],
            "Purchase"
          )
        : "",
    inv:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter(
              (obj) => obj?.docType?.name === "PoInvoice"
            ) ?? []
          )
        : "",
    invoiceValue: row?.totalInvoiceAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalInvoiceAmount).toFixed(2))
      : "",
    amountPaid: row?.totalPaidAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalPaidAmount).toFixed(2))
      : "",
    balance:
      row?.totalInvoiceAmount && row?.totalPaidAmount
        ? currencyOnlyFormatter(
            parseFloat(
              +row?.totalInvoiceAmount - +row?.totalPaidAmount
            ).toFixed(2)
          )
        : "",
    avgDue: row?.avgDue ?? "",
    ...{
      ...(row?.purchaseReturns?.length > 0
        ? {
            subRow: row.purchaseReturns.map((pr, index) => {
              const getDoc =
                pr?.documents?.length > 0
                  ? getDocumentsByType(
                      pr?.documents?.filter(
                        (obj) => obj?.docType?.name === "GatePass"
                      ) ?? [],
                      "Purchase"
                    )
                  : "";
              const getDNDoc =
                pr?.documents?.length > 0
                  ? getDocumentsByType(
                      pr?.documents?.filter(
                        (obj) => obj?.docType?.name === "DebitNote"
                      ) ?? []
                    )
                  : "";
              return {
                returnId: pr?._id,
                returnCode: pr?.code ?? "PR-Draft",
                returnOrderDate: moment(pr?.orderDate).format("DD-MM-YYYY"),
                returnOrderStatus: pr?.status?.name,
                docNo: getCustomCell(
                  pr?.code ?? "PR-Draft",
                  moment(pr?.orderDate).format("DD-MM-YYYY"),
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${row?._id}/purchaseReturns/${pr?._id}`
                ),
                amount: currencyOnlyFormatter(
                  parseFloat(pr?.totalAmount).toFixed(2)
                ),
                orderProgress: (
                  <StyledChip label={stringCapitalization(pr?.status?.name)} />
                ),
                mrr: getDoc
                  ? getCustomCell(getDoc[0], getDoc[1], getDoc[2], getDoc[3])
                  : "",
                inv: getDNDoc
                  ? getCustomCell(
                      getDNDoc[0],
                      getDNDoc[1],
                      getDNDoc[2],
                      getDNDoc[3]
                    )
                  : "",
                invoiceValue: pr?.totalNoteAmount ? (
                  <Typography fontSize={14} color={"#F05454"}>
                    {`(${currencyOnlyFormatter(
                      parseFloat(pr?.totalNoteAmount).toFixed(2)
                    )})`}
                  </Typography>
                ) : (
                  ""
                ),
                amountPaid: "",
                balance: "",
                avgDue: ""
              };
            })
          }
        : {})
    }
  }));
};

const PurchaseOrders = () => {
  const [params, setParams] = useState();
  const { showDrawer, hideDrawer } = useDrawer();
  const navigate = useNavigate();
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      [SalesOrders_filterKeys.Headers]: []
    }
  });

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchPOFilters();

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchPOSubtotal(params);

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchPurchaseOrders(params);

  const onAction = useCallback(
    (orderId) => {
      showDrawer({
        component: <POTransactions hideDrawer={hideDrawer} orderId={orderId} />,
        closeIcon: false
      });
    },
    [hideDrawer, showDrawer]
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const purchaseTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(onAction)}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.purchaseOrders)) ??
              []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobilePurchaseOrders}
      />
    ),
    [data?.pages, isFetched, onAction]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["warehouse"] !== ""
          ? {
              [`filters[warehouse]`]: data["warehouse"]
            }
          : {})
      },
      ...{
        ...(data["vendor"] !== ""
          ? {
              [`filters[vendor]`]: data["vendor"]
            }
          : {})
      },
      ...{
        ...(data["status"] !== ""
          ? {
              [`filters[status]`]: data["status"]
            }
          : {})
      },
      ...{
        ...(data?.date[0] !== null && data?.date[0] !== undefined
          ? {
              [`filters[fromDate]`]: moment(data?.date[0]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[1] !== null && data?.date[1] !== undefined
          ? {
              [`filters[toDate]`]: moment(data?.date[1]).format("YYYY-MM-DD")
            }
          : {})
      }
    };
    setParams(filterData);
    hideDrawer();
  };

  const onClear = () => {
    reset({
      warehouse: "",
      vendor: "",
      status: "",
      date: ""
    });
    setParams(null);
  };

  const FilterComponent = (
    <Box>
      <Filter
        control={control}
        onApply={handleSubmit(onViewClick)}
        onClear={onClear}
        isFetching={isFilterFetching}
        isLoading={isFetching}
        filters={[
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.warehouse,
            label: salesOrder_labels.warehouse,
            unSelectText: "Select Warehouse",
            options: dropdownOptions(filterData?.warehouses, "_id", "code"),
            filterCount: filterData?.warehouses?.length
          },
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.vendor,
            label: salesOrder_labels.vendor,
            unSelectText: "Select Vendor",
            allowSearch: true,
            options: dropdownOptions(filterData?.vendors, "_id", "companyName"),
            filterCount: filterData?.vendors?.length
          },
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.status,
            unSelectText: "Select Status",
            label: salesOrder_labels.status,
            options: filterData?.statuses,
            filterCount: filterData?.statuses?.length
          },
          {
            type: "DateInput",
            name: SalesOrders_filterKeys.date,
            label: salesOrder_labels.date,
            selectsRange: true
          }
        ]}
      />
    </Box>
  );

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid
          item
          xs={12}
          textAlign={"end"}
          container
          columnSpacing={4}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`
                )
              }
            >
              {purchase_labels.stockTransfersButton}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`
                )
              }
            >
              {purchase_labels.stockAdjustmentsButton}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.createOrder}`
                )
              }
            >
              {purchase_labels.createOrderButton}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {FilterComponent}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "0.6px solid #2773FF",
              borderRadius: 2,
              padding: 4,
              display: "flex",
              gap: 3,
              justifyContent: "flex-end"
            }}
          >
            {isFetchingSubtotals ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <CustomTypo color="primary">Subtotal :</CustomTypo>
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.totalInvoiceAmount
                    ? currencyFormatter(
                        parseFloat(
                          subTotalAmount?.subTotals?.totalInvoiceAmount
                        ).toFixed(2)
                      )
                    : "--",
                  "Invoice Value"
                )}
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.totalPaidAmount
                    ? currencyFormatter(
                        parseFloat(
                          subTotalAmount?.subTotals?.totalPaidAmount
                        ).toFixed(2)
                      )
                    : "--",
                  "Amt Paid"
                )}
                {SubtotalTypo(
                  !Boolean(subTotalAmount?.subTotals?.totalPaidAmount)
                    ? subTotalAmount?.subTotals?.totalInvoiceAmount
                      ? currencyOnlyFormatter(
                          parseFloat(
                            +subTotalAmount?.subTotals?.totalInvoiceAmount
                          ).toFixed(2)
                        )
                      : "--"
                    : currencyOnlyFormatter(
                        parseFloat(
                          +subTotalAmount?.subTotals?.totalInvoiceAmount -
                            +subTotalAmount?.subTotals?.totalPaidAmount
                        ).toFixed(2)
                      ),
                  "Balance"
                )}
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.avgDueDays ?? "--",
                  "Avg. Due"
                )}
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            variant="link"
            disableRipple
            sx={{ fontWeight: 700 }}
            onClick={() =>
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`
              )
            }
          >
            {purchase_labels.stockTransfersMobileButton}
          </Button>
          <Button
            variant="link"
            disableRipple
            sx={{ fontWeight: 700 }}
            onClick={() =>
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`
              )
            }
          >
            {purchase_labels.stockAdjustmentsMobileButton}
          </Button>
          <Button
            variant="link"
            disableRipple
            sx={{ fontWeight: 700 }}
            onClick={() =>
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.createOrder}`
              )
            }
          >
            {purchase_labels.createOrderMobileButton}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <DropDown
            control={control}
            name={"PO_id"}
            placeholder={"Search Order"}
            startIcon={Search}
            options={[]}
            allowSearch
            availUnselect
            unSelectText="Select Order"
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"right"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{purchaseTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default PurchaseOrders;
