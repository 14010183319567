import { Box, Button, FormHelperText, Typography } from "@mui/material";

import DateInput from "../../shared/formElements/DateInput";
import { InvoiceUploadKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import SkuImageUpload from "../../shared/formElements/SkuImageUpload";
import PDFICON from "../../assets/pdfFile.png";
import { handleDownload } from "../../warehouse/components/MaterialReceivedReport/MrrTable";
import { LabelValueText } from "../../warehouse/pages/MaterialReceivedReports";

const InvoiceDetailManualTab = ({
  control,
  setValue,
  setShowManual,
  onSubmit,
  errors,
  InvInfo = "",
  Title
}) => {
  return (
    <Box
      padding={5}
      display={"flex"}
      justifyContent={"space-between"}
      gap={2}
      flexDirection={"column"}
      boxShadow={"0px 8px 24px 0px #1515161A"}
    >
      <Typography fontWeight={600}>
        {Boolean(InvInfo) ? Title : `Upload ${Title}`}
      </Typography>
      <Box display={"flex"} gap={2}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"50%"}
          height={"402px"}
        >
          {Boolean(InvInfo) ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ background: "#F3F5F9" }}
              width={"100%"}
              height={"100%"}
            >
              {InvInfo?.file?.mimeType === "application/pdf" ? (
                <>
                  <img
                    src={PDFICON}
                    alt={InvInfo?.file?.originalName}
                    style={{
                      width: "20%",
                      height: "20%"
                    }}
                  />
                  <Button variant="link" disableRipple>
                    {InvInfo?.file?.originalName}
                  </Button>
                </>
              ) : (
                <img
                  src={InvInfo?.file?.fileUrl}
                  alt="Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
              )}
            </Box>
          ) : (
            <>
              <SkuImageUpload
                control={control}
                name={InvoiceUploadKeys.manualInvoice}
                setValue={setValue}
                uploadButtonText={"Browse file"}
                invoiceType
              />
              <FormHelperText error>
                {errors.manualInvoice?.message}
              </FormHelperText>
            </>
          )}
        </Box>
        {Boolean(InvInfo) ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
            width={"50%"}
          >
            <LabelValueText label={`${Title} ID : `} value={InvInfo?.code} />
            <LabelValueText label={`${Title} Date : `} value={InvInfo?.date} />
            <LabelValueText
              label={`${Title} Value : `}
              value={InvInfo?.amount}
            />
            <LabelValueText
              label={`Payement Terms : `}
              value={`${InvInfo?.advance}% Advance. Balance Due ${InvInfo?.due} days from receipt`}
            />
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
            width={"50%"}
          >
            <Box width={"172px"}>
              <TextInput
                control={control}
                label={`${Title} ID`}
                placeholder={"Enter ID"}
                name={InvoiceUploadKeys.invoiceId}
              />
            </Box>
            <Box width={"172px"}>
              <DateInput
                control={control}
                placeholder={"Enter date"}
                label={`${Title} date`}
                name={InvoiceUploadKeys.invoiceDate}
                minDate={"2000-01-01"}
              />
            </Box>
            <Box width={"172px"}>
              <TextInput
                control={control}
                label={`${Title} Value`}
                placeholder={`Enter ${Title} Value`}
                name={InvoiceUploadKeys.invoiceAmount}
              />
            </Box>
          </Box>
        )}
      </Box>
      {Boolean(InvInfo) ? (
        <Box display={"flex"} gap={"10px"} justifyContent={"end"}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: 42,
              width: {
                xs: "100%",
                md: 164
              }
            }}
            onClick={() => handleDownload(InvInfo?.file?.fileUrl)}
          >
            Download
          </Button>
        </Box>
      ) : (
        <Box display={"flex"} gap={"10px"} justifyContent={"end"}>
          <Button
            variant="soft"
            sx={{
              height: "42px",
              width: "205px"
            }}
            onClick={() => setShowManual(false)}
          >
            {`Skip and Raise ${Title}`}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: 42,
              width: {
                xs: "100%",
                md: 164
              }
            }}
            onClick={onSubmit}
          >
            Upload
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default InvoiceDetailManualTab;
