import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  ListingsFormKeys,
  appServiceKeys,
  cartServiceKeys,
  rfqServiceKeys
} from "../../constants/formKeys";
import { isMobile, isTablet } from "react-device-detect";

import { AppContext } from "../../shared/context/auth-context";
import { Auth } from "aws-amplify";
import Cart from "../components/Cart";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import DropDown from "../../shared/formElements/DropDown";
import Filters from "../components/Filters";
import { Global } from "@emotion/react";
import ListingCard from "../components/ListingCard";
import Login from "../../shared/layouts/Login";
import SendRFQ from "../components/SendRFQ";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { grey } from "@mui/material/colors";
import { labels } from "../../constants";
import { stringCapitalization } from "../../shared/utils";
import styled from "@emotion/styled";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchTransporters } from "../../query-hooks/Transport/useFetchTransporters";
import { useFetchWarehouses } from "../../query-hooks/Warehouse/useFetchWarehouses";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useFetchListings } from "../../query-hooks/Listings/useFetchListings";
import { ArrowBack, TuneOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import GoogleMapContainer from "../../shared/layouts/GoogleMapContainer";

const isMobileDevice = isMobile || isTablet;

const Puller = styled(Box)(() => ({
  width: 40,
  height: 3,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)"
}));

const borderRadius = {
  borderTopLeftRadius: 50,
  borderTopRightRadius: 50
};

const drawerBleeding = 0;

const HalfOpenSwipeableDrawer = ({ children }) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      variant="persistent"
      open={true}
      hideBackdrop
      disableSwipeToOpen
      onClose={() => {}}
      onOpen={() => {}}
      ModalProps={{
        keepMounted: true
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          py: 1,
          ...borderRadius
        }}
      >
        <Puller />
      </Box>
      <Box px={2}>{children}</Box>
    </SwipeableDrawer>
  );
};

const Listings = () => {
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);
  const listingFormParams = Storage.getItem(StorageKeys.SEARCH_LISTING_PARAMS);

  const initialRfqStateObj = {
    [rfqServiceKeys.warehouse]: [],
    [rfqServiceKeys.transport]: []
  };

  const { ref, inView } = useInView({ threshold: 0 });

  const navigate = useNavigate();

  const [formParams, setFormParams] = useState(searchFormParams);
  const [sendRfqObject, setSendRfqObject] = useState(initialRfqStateObj);
  const { onAddToCart, activeTab, handleTabChange, onRemoveFromCart } =
    useContext(AppContext);

  const { showDrawer, hideDrawer, isDrawerOpen } = useDrawer();
  const { showDialog } = useCustomDialog();

  const {
    isFetching: isPartnerFetching,
    data: listingData,
    isSuccess: isListingSuccess,
    hasNextPage: hasListingNextPage,
    fetchNextPage: fetchListingNextPage,
    isFetchingNextPage: isFetchingListingNextPage
  } = useFetchListings(
    !Boolean(formParams) &&
      (service?.["warehouse"] || service?.["transport"]) &&
      listingFormParams
  );

  const {
    isFetching: isWarehouseFetching,
    data: warehouseData,
    isSuccess: isWarehouseSuccess,
    hasNextPage: hasWarehouseNextPage,
    fetchNextPage: fetchWarehouseNextPage,
    isFetchingNextPage: isFetchingWarehouseNextPage,
    refetch: refetchWarehouses
  } = useFetchWarehouses(service?.["warehouse"] && formParams);

  const {
    data: transportData,
    isFetching: isTransportFetching,
    isSuccess: isTransportSuccess,
    hasNextPage: hasTransportNextPage,
    fetchNextPage: fetchTransportNextPage,
    isFetchingNextPage: isFetchingTransportNextPage,
    refetch: refetchTransports
  } = useFetchTransporters(service?.["transport"] && formParams);

  const { control } = useForm({
    defaultValues: {
      [ListingsFormKeys.SortBy]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const showFilters = () => {
    showDrawer({
      title: "Filters",
      height: "fit-content",
      component: (
        <Grid p={2}>
          <Filters applyFilters={(params) => setFormParams(params)} />
        </Grid>
      )
    });
  };

  const onSendRfqClick = (service, item) => {
    const rfqObj = { ...sendRfqObject };

    if (Boolean(searchFormParams)) {
      if (typeof item === "number") {
        rfqObj[service].splice(item, 1);
        setSendRfqObject(rfqObj);
      } else {
        if (rfqObj[service]?.length <= 2) {
          const index = rfqObj[service]?.findIndex(
            (rfqItem) => rfqItem._id === item._id
          );
          if (index === -1) {
            rfqObj[service]?.push(item);
            setSendRfqObject(rfqObj);
          }
        }
      }
      showDrawer({
        component: (
          <SendRFQ
            sendRfqObj={rfqObj}
            service={service}
            updateTabs={(stepperActiveTab) => handleTabChange(stepperActiveTab)}
            resetObject={() => setSendRfqObject(initialRfqStateObj)}
            closeDrawer={hideDrawer}
            onRemove={(type, index) => onSendRfqClick(type, index)}
            checkIsLoggedIn={checkIsLoggedIn}
          />
        ),
        closeIcon: false
        // variant: "permanent"
      });
    } else {
      if (isMobileDevice) showFilters();
      else
        enqueueSnackbar("Kindly Apply mandatory Filters", {
          variant: "error"
        });
    }
  };

  const handleCart = (service, item = null) => {
    if (Boolean(searchFormParams)) {
      onAddToCart(service, item);
      showDrawer({
        component: (
          <Cart
            updateTabs={(stepperActiveTab) => handleTabChange(stepperActiveTab)}
            onRemove={(type) => onRemoveFromCart(type)}
            checkIsLoggedIn={checkIsLoggedIn}
          />
        ),
        closeIcon: false
        // variant: "permanent"
      });
    } else {
      if (isMobileDevice) showFilters();
      else
        enqueueSnackbar("Kindly Apply mandatory Filters", {
          variant: "error"
        });
    }
  };

  async function checkIsLoggedIn() {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (!authenticatedUser) {
        showDialog({
          component: <Login hideDemoLogin />,
          size: "xs",
          backdropOff: true
        });
      }
    } catch (error) {
      showDialog({
        component: <Login hideDemoLogin />,
        size: "xs",
        backdropOff: true
      });
    }
  }

  useEffect(() => {
    if (formParams) {
      if (service?.warehouse) refetchWarehouses();

      if (service?.transport) refetchTransports();
    }
  }, [
    formParams,
    refetchTransports,
    refetchWarehouses,
    service?.transport,
    service?.warehouse
  ]);

  useEffect(() => {
    if (inView && hasWarehouseNextPage) {
      fetchWarehouseNextPage();
    }
  }, [fetchWarehouseNextPage, hasWarehouseNextPage, inView]);

  useEffect(() => {
    if (inView && hasTransportNextPage) {
      fetchTransportNextPage();
    }
  }, [fetchTransportNextPage, hasTransportNextPage, inView]);

  useEffect(() => {
    if (inView && hasListingNextPage) {
      fetchListingNextPage();
    }
  }, [fetchListingNextPage, hasListingNextPage, inView]);

  const showWarehouses = Boolean(searchFormParams)
    ? !isWarehouseFetching && isWarehouseSuccess && warehouseData
    : !isPartnerFetching && isListingSuccess && listingData;

  const showTransports = Boolean(searchFormParams)
    ? !isTransportFetching && isTransportSuccess && transportData
    : !isPartnerFetching && isListingSuccess && listingData;

  const warehouse = (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          paddingBottom: "60px"
        }}
        container
        spacing={2}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            isWarehouseFetching ||
            isFetchingWarehouseNextPage ||
            isPartnerFetching ||
            isFetchingListingNextPage
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {showWarehouses?.pages?.map((page, index) => (
          <Fragment key={index}>
            {page.warehouses?.map((item, i) =>
              page.warehouses?.length === i + 1 &&
              (hasWarehouseNextPage || hasListingNextPage) ? (
                <Grid
                  item
                  xs={12}
                  ref={ref}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid item xs={12} md={12} lg={6} key={item._id}>
                  <ListingCard
                    service={appServiceKeys.warehouse}
                    title={item.code}
                    heroImage={item.heroImage?.thumbUrl}
                    city={item.address.cluster}
                    state={item.address.city}
                    country={item.address.state}
                    monthlyRental={item.storageTypes[0].rate}
                    rateUnit={item.storageTypes[0].rateUnit}
                    addonFees={item?.costs?.additionalServiceCost || 0}
                    totalAmount={item?.costs?.totalAmount || 0}
                    thumbnail={item?.files?.[0].thumbUrl}
                    id={item._id}
                    sendRfqList={sendRfqObject[rfqServiceKeys.warehouse]}
                    onSendRfqClick={() => {
                      onSendRfqClick(rfqServiceKeys.warehouse, item);
                    }}
                    onAddToCartClick={() =>
                      handleCart(cartServiceKeys.warehouse, item)
                    }
                  />
                </Grid>
              )
            )}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );

  const transporter = (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        sx={{
          paddingBottom: "60px"
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            isTransportFetching ||
            isFetchingTransportNextPage ||
            isPartnerFetching ||
            isFetchingListingNextPage
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {showTransports?.pages?.map((page, index) => (
          <Fragment key={index}>
            {page.transports?.map((item, i) =>
              page.transports?.length === i + 1 && hasTransportNextPage ? (
                <Grid item xs={12} ref={ref} />
              ) : (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={isDrawerOpen ? 6 : 4}
                  key={item._id}
                >
                  <ListingCard
                    service={appServiceKeys.transport}
                    title={item.code}
                    city={item.address.cluster}
                    state={item.address.city}
                    country={item.address.state}
                    monthlyRental={item.vehicles[0].rate}
                    rateUnit={item.vehicles[0].rateUnit}
                    addonFees={item?.costs?.additionalServiceCost || 0}
                    totalAmount={item?.costs?.totalAmount || 0}
                    id={item._id}
                    thumbnail={item?.files?.[0].thumbUrl}
                    heroImage={item?.vehicles[0]?.heroImage?.thumbUrl}
                    tranName={
                      item.vehicles[0].make.displayName +
                      " " +
                      item.vehicles[0].model.displayName
                    }
                    loadCap={item.vehicles[0].weightCapacity}
                    sendRfqList={sendRfqObject[rfqServiceKeys.transport]}
                    onSendRfqClick={() =>
                      onSendRfqClick(rfqServiceKeys.transport, item)
                    }
                    onAddToCartClick={() =>
                      handleCart(cartServiceKeys.transport, item)
                    }
                  />
                </Grid>
              )
            )}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );

  const components = {
    warehouse,
    transport: transporter
  };

  const tabs =
    service &&
    [
      {
        key: "warehouse",
        label: `${
          warehouseData?.pages?.[0]?.pagination.rows ||
          listingData?.pages?.[0]?.pagination.rows ||
          0
        } results`
      },
      {
        key: "transport",
        label: `${
          transportData?.pages?.[0]?.pagination.rows ||
          listingData?.pages?.[0]?.pagination.rows ||
          0
        } results`
      }
    ]
      .map((property) =>
        service[property.key]
          ? {
              label: stringCapitalization(property.label),
              Component: components[property.key]
            }
          : null
      )
      .filter(Boolean);

  const Tabs = (
    <>
      {!isMobileDevice && (
        <Grid item xs={12}>
          <Filters applyFilters={(params) => setFormParams(params)} />
        </Grid>
      )}
      <Grid
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 168px)"
        }}
        item
        xs={12}
        md={activeTab === 0 && service?.warehouse ? 7 : isDrawerOpen ? 7 : 12}
      >
        <CustomTabs
          headerControls={
            <Box
              alignItems={"center"}
              minWidth={250}
              display={{
                xs: "none",
                sm: "flex"
              }}
            >
              <Typography
                fontWeight={700}
                fontSize={14}
                whiteSpace={"nowrap"}
                marginRight={2}
              >
                {labels.sortBy}
              </Typography>
              <DropDown
                name={ListingsFormKeys.SortBy}
                control={control}
                placeholder={labels.sortByPlaceholderText}
                options={[
                  {
                    name: "relevance", // will be removed with API integration
                    displayName: "Relevance"
                  }
                ]}
              />
            </Box>
          }
          tabs={tabs}
          activeTab={activeTab}
          onChange={handleTabChange}
        />
      </Grid>
      {activeTab === 0 && service?.warehouse && (
        <Grid
          item
          xs={12}
          md={5}
          display={{
            xs: "none",
            sm: "block"
          }}
        >
          {(!isWarehouseFetching || !isPartnerFetching) && (
            <GoogleMapContainer
              data={warehouseData?.pages || listingData?.pages}
            />
          )}
        </Grid>
      )}
    </>
  );

  return (
    <Box>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "flex", sm: "none" },
          alignItems: "center",
          justifyContent: "space-between",
          padding: "6px 20px 6px 6px",
          background: "#FFFFFF",
          boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
          gap: 2
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            navigate("/explore");
          }}
          sx={{ marginRight: 1 }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          sx={{
            backgroundColor: "#F0F2F5",
            display: {
              xs: "flex",
              sm: "none"
            }
          }}
          onClick={showFilters}
        >
          <TuneOutlined color="primary" fontSize="small" />
        </IconButton>
      </Grid>
      <Grid
        container
        spacing={2}
        display={{
          xs: "flex",
          sm: "none"
        }}
      >
        {service?.["warehouse"] ? (
          <>
            <Grid item xs={12}>
              {isMobileDevice &&
                (!isWarehouseFetching || !isPartnerFetching) && (
                  <GoogleMapContainer
                    data={warehouseData?.pages || listingData?.pages}
                  />
                )}
            </Grid>
            <Global
              styles={{
                ".MuiDrawer-root > .MuiPaper-root": {
                  height: `calc(50% - ${drawerBleeding}px)`,
                  overflow: "auto",
                  boxShadow: isMobileDevice
                    ? "0px -8px 24px 0px #1515161A"
                    : "none"
                }
              }}
            />
            <Grid item xs={12}>
              <HalfOpenSwipeableDrawer>{Tabs}</HalfOpenSwipeableDrawer>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            {Tabs}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        display={{
          xs: "none",
          sm: "flex"
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            navigate("/explore");
          }}
          sx={{ marginRight: 1, marginLeft: -1 }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
        {Tabs}
      </Grid>
    </Box>
  );
};

export default Listings;
