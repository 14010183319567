import {
  Switch,
  Tooltip,
  Typography,
  createTheme,
  styled,
  tooltipClasses
} from "@mui/material";

export const primaryBackground = "#D4E3FF80";
export const secondaryBackground = "#F3F5F9";
export const otherControlsResting = "#E3E7EB";
export const otherControlsHover = "#C7CED4";
export const errorBackground = "#FEEEEE";
const disabled = "#F0F2F5";

export const globalTheme = createTheme({
  palette: {
    primary: {
      main: "#2773FF",
      dark: "#1B51B3",
      light: "#689DFF"
    },
    secondary: {
      main: "#E3E8F0",
      dark: "#BBCADF",
      light: "#EFF2F7",
      background: "#F3F5F9"
    },
    success: {
      main: "#16C79A",
      dark: "#0F8B6C",
      light: "#5CD8B8",
      background: "#E8F9F5"
    },
    error: {
      main: "#F05454",
      dark: "#A83B3B",
      light: "#F58787",
      background: "#FEEEEE"
    },
    warning: {
      main: "#FFC453",
      dark: "#CC9D42",
      light: "#FFD687",
      background: "#FFF9EE"
    },
    text: {
      primary: "#0F2744",
      secondary: "#607088",
      disabled: "#95A1B0",
      title: "#2773FF"
    },
    divider: "#E6EAEE",
    action: {
      disabledBackground: "#E0E4EB",
      disabled: "#ABB8CA"
    },
    received: {
      dark: "#816CFF",
      background: "#816CFF1A"
    },
    transit: {
      dark: "#FF7020",
      background: "#FF70201A"
    }
  },
  typography: {
    fontFamily: ["Inter"]
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.variant === "outlined" ||
            ownerState.variant === "text") && {
            "&:hover": {
              background: primaryBackground
            }
          }),
          textTransform: "capitalize",
          borderRadius: "8px",
          height: "100%",
          fontSize: 15,
          lineHeight: "20px",
          letterSpacing: "0.02em"
        })
      },
      variants: [
        {
          props: { variant: "soft" },
          style: ({ theme }) => {
            return {
              color: theme.palette.primary.main,
              backgroundColor: primaryBackground,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: "white"
              }
            };
          }
        },
        {
          props: { variant: "softError" },
          style: ({ theme }) => {
            return {
              color: theme.palette.error.main,
              backgroundColor: errorBackground,
              "&:hover": {
                backgroundColor: theme.palette.error.main,
                color: "white"
              }
            };
          }
        },
        {
          props: { variant: "link" },
          style: ({ theme }) => {
            return {
              color: theme.palette.primary.main,
              backgroundColor: "transparent",
              padding: 0,
              height: "auto",
              fontSize: 14,
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "transparent"
              }
            };
          }
        }
      ]
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          transform: "none",
          zIndex: "auto",
          "&.MuiFormLabel-filled , &.Mui-focused": {
            color: theme.palette.text.primary,
            fontWeight: 500
          },
          "&.Mui-disabled": {
            color: theme.palette.text.disabled
          },
          "& + div": {
            marginTop: theme.spacing(3.2)
          }
        })
      },
      defaultProps: {}
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: "1px solid #B7BEC7",
          background: "white",
          borderRadius: "8px",
          padding: 0,
          overflow: "hidden",
          width: "100%",
          "&.Mui-focused": {
            border: `1px solid ${theme.palette.primary.main}`
          },
          "&.Mui-error": {
            borderColor: theme.palette.error.main
          },
          "&.Mui-disabled": {
            borderColor: disabled
          },
          "&:hover": {
            borderColor: disabled,
            "&.Mui-focused": {
              borderColor: theme.palette.primary.main
            },
            "&.Mui-error": {
              borderColor: theme.palette.error.main
            },
            "& > .MuiOutlinedInput-notchedOutline": {
              borderColor: `${disabled} !important`
            }
          }
        }),
        input: ({ theme }) => ({
          padding: "12px 16px !important",
          fontSize: "14px",
          color: theme.palette.text.primary,
          "&::placeholder": {
            color: theme.palette.text.disabled,
            opacity: 1
          }
        })
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "60px",
          "@media (min-width: 600px)": {
            minHeight: "72px"
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "14px",
          lineHeight: "17px",
          transform: "none",
          color: theme.palette.text.primary,
          "&.Mui-focused": {
            color: theme.palette.text.primary,
            fontWeight: 500
          }
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 8
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderWidth: 0,
          padding: "12px 16px",
          marginRight: 16
        },
        root: {
          borderRadius: 8,
          border: 0,
          "& + .Mui-error": {
            marginLeft: 0
          },
          "&.Mui-focused": {
            border: 0
          },
          "&:hover": {
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F05454 !important"
            }
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: disabled
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderWidth: "1px !important"
        })
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: "#FFFFFF",
          boxShadow: "0px 2px 5px rgba(1, 16, 34, 0.1)",
          borderRadius: 8,
          marginTop: 7,
          "& > .MuiList-root": {
            padding: 8,
            "& > li": {
              background: "#FFFFFF",
              borderRadius: 8,
              fontWeight: 400,
              fontSize: 14,
              padding: "12px 16px",
              lineHeight: "150%",
              color: "#0F0F0F",
              "&:hover": {
                background: "#F4F5F8"
              },
              "&.Mui-selected": {
                background: "#F4F6F6",
                color: "#1F7E4B",
                fontWeight: 600,
                marginBottom: 6
              }
            }
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          color: "#808080"
        },
        root: {
          // marginRight: 22,
        }
      },
      defaultProps: {
        sx: {
          marginRight: {
            md: "25px"
          }
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled > input:hover + span": {
            background: disabled
          },
          padding: 0,
          paddingRight: "9px",
          "&.Mui-checked + span": {
            color: "#0F2744"
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          padding: 8
        },
        paper: {
          borderRadius: 8,
          boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
          marginTop: 7
        },
        option: ({ theme }) => ({
          fontSize: 14,
          padding: "12px 16px !important",
          color: theme.palette.text.primary,
          "&.Mui-focused": {
            borderRadius: 8,
            backgroundColor: "#F4F5F8 !important"
          }
        }),
        inputRoot: {
          paddingRight: "0px !important"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& + span": {
            color: `${theme.palette.text.primary} !important`
          }
        })
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          "&:last-child": {
            padding: 0
          }
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
          marginTop: 24,
          justifyContent: "flex-end"
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          // color: theme.palette.text.secondary,
        })
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "capitalize",
          color: theme.palette.text.secondary,
          borderBottom: "1.2px solid #B7BEC7",
          fontSize: 14,
          "&.Mui-selected": {
            color: theme.palette.text.primary,
            fontWeight: 600
          }
        })
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          height: 0,
          border: `1.2px solid ${theme.palette.primary.main}`
        }),
        root: ({ theme }) => ({
          [theme.breakpoints.down("sm")]: {
            flexGrow: 1
          }
        })
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          height: "24px !important",
          width: "24px !important",
          top: "11px !important",
          right: "11px !important"
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-completed": {
            color: theme.palette.success.main
          }
        })
      }
    }
  }
});

export const StyledResponsiveText = styled((props) => (
  <Typography
    {...props}
    sx={{
      fontSize: {
        md: props?.desktop?.fontSize,
        xs: props?.mobile?.fontSize
      },
      fontWeight: {
        md: props?.desktop?.fontWeight,
        xs: props?.mobile?.fontWeight
      },
      lineHeight: {
        md: props?.desktop?.lineHeight,
        xs: props?.mobile?.lineHeight
      },
      letterSpacing: {
        md: props?.desktop?.letterSpacing,
        xs: props?.mobile?.letterSpacing
      },
      mb: {
        md: props?.desktop?.mb,
        xs: props?.mobile?.mb
      }
    }}
  />
))(({ theme }) => ({}));

export const StyledArrowUp = styled((props) => <div {...props} />)`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #607088;
  margin-bottom: 2px;
`;

export const StyledArrowDown = styled("div")`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #607088;
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20,
      height: 20
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)"
    }
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F9A90E"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: "50%",
    transition: theme.transitions.create(["width"], {
      duration: 200
    })
  },
  "& .MuiSwitch-track": {
    borderRadius: "16px",
    opacity: 1,
    backgroundColor: "#2773FF",
    boxSizing: "border-box"
  }
}));
