import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { isMobileDevice } from "../../App";
import { useEffect, useState } from "react";

function TabPanel(props) {
  const { children, value, index, fixTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: "20px" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`
  };
}

export const CustomTabs = ({
  tabs,
  headerControls,
  backgroundColor,
  activeTab,
  onChange,
  secondaryTab = false,
  nested = false,
  fixTab = false
}) => {
  const handleTabChange = (_, newValue) => {
    onChange(newValue);
  };

  const theme = useTheme();

  const [isFixed, setIsFixed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setScrollPosition(currentScrollPosition);

      if (fixTab && currentScrollPosition > 120) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fixTab]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{
          backgroundColor: backgroundColor ? backgroundColor : "white",
          position: isFixed ? "fixed" : "relative",
          top: isFixed ? "59px" : "auto",
          left: 0,
          right: 0,
          zIndex: isFixed ? theme.zIndex.appBar : "auto",
          boxShadow: "none"
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          scrollButtons={false}
          variant={
            isMobileDevice
              ? tabs.length > 3
                ? "scrollable"
                : "fullWidth"
              : "standard"
          }
          sx={{
            ".MuiTabs-scroller": {
              overflow: "auto !important"
            },
            backgroundColor: secondaryTab ? "#EEF1F4" : "white",
            boxShadow: secondaryTab
              ? "0px 8px 24px rgba(21, 21, 22, 0.04)"
              : "none",
            width: "100%",
            "& .MuiTabs-indicator": {
              display: nested ? "none" : "inline-block"
            },
            "& button": {
              borderBottom: nested ? 0 : "1.2px solid #B7BEC7",
              color: nested
                ? theme.palette.text.disabled
                : theme.palette.text.secondary,
              fontWeight: 400
            },
            "& .Mui-selected": {
              color: nested
                ? theme.palette.primary.main
                : theme.palette.text.primary
            }
          }}
          allowScrollButtonsMobile={tabs?.length > 3}
        >
          {tabs?.map(({ label }, index) => (
            <Tab
              label={label}
              key={index}
              {...a11yProps(index)}
              disableRipple
              sx={{ whiteSpace: "nowrap" }}
            />
          ))}
        </Tabs>
        {headerControls}
      </Box>
      {tabs?.map(({ Component }, index) => (
        <TabPanel value={activeTab} index={index} key={index} fixTab={fixTab}>
          {Component}
        </TabPanel>
      ))}
    </Box>
  );
};
