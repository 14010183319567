import React, { useRef, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ApparelImage1 from "../../assets/Clothing.png";
import FoodImage1 from "../../assets/fmcg.png";
import furnitureImage1 from "../../assets/furniture.png";
import ApparelImage2 from "../../assets/Clothing-desktop.png";
import FoodImage2 from "../../assets/fcmg-desktop.png";
import furnitureImage2 from "../../assets/furniture-desktop.png";
import IndustryCardItem from "./IndustryCardItem";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";

const industries = [
  {
    label: home_labels["FMCG/F&B"],
    image1: FoodImage1,
    image2: FoodImage2,
    id: "food-and-fmcg"
  },
  {
    label: home_labels["apparel&TextilesText"],
    image1: ApparelImage1,
    image2: ApparelImage2,
    id: "apparel"
  },
  {
    label: home_labels.Furniture,
    image1: furnitureImage1,
    image2: furnitureImage2,
    id: "furniture"
  }
];

const IndustriesSection = () => {
  const sectionRef = useRef(null);
  const sliderRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      const entry = entries[0];
      setIsInView(entry.intersectionRatio >= 0.5);
    };

    const observerOptions = {
      threshold: [0.5]
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      if (isInView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [isInView]);

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current && isInView) {
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false, // Default to false
    autoplaySpeed: 2000
  };

  return (
    <Box
      id="industries"
      ref={sectionRef}
      sx={{
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "20px",
          md: "5px 65px"
        },
        gap: { xs: 1, md: 2 }
      }}
      textAlign={"center"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SectionHeader title={home_labels.industriesServedText} />
      {isMobile ? (
        <Slider ref={sliderRef} {...settings}>
          {industries.map((item) => (
            <div key={item.label}>
              <Grid item xs={12} key={item.label} style={{ marginRight: "16px" }}>
                <IndustryCardItem {...item} />
              </Grid>
            </div>
          ))}
        </Slider>
      ) : (
        <Grid container spacing={2}>
          {industries.map((item) => (
            <Grid item md={4} key={item.label}>
              <IndustryCardItem {...item} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default IndustriesSection;
