import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../shared/context/auth-context";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import ExploreFormBg from "../../assets/Explore.png";
import RadioCard from "./RadioCard";
import { useForm } from "react-hook-form";
import { DrawerButton } from "../../inventory/components/SKU/SkuDetailsForm";
import { ArrowBack, TrendingFlat } from "@mui/icons-material";
import { searchFormType, StorageKeys } from "../../constants/storage-keys";
import Storage from "../../shared/utils/Storage";
import { useFetchListings } from "../../query-hooks/Listings/useFetchListings";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { cartServiceKeys, SearchFormKeys } from "../../constants/formKeys";
import { errorStrings } from "../../constants/errorStrings";

export const vehicleWeights = { light: "x<1", medium: "1<=x<=5", heavy: "5<x" };

const Explore = () => {
  const [showTruckTypeSelection, setShowTruckTypeSelection] = useState(false);
  const [formParams, setFormParams] = useState();
  const { currentUser, onRemoveFromCart } = useContext(AppContext);

  const navigate = useNavigate();

  const localFormType = Storage.getItem(StorageKeys.SEARCH_FORM_TYPE);
  const FORM_DATA = Storage.getItem(StorageKeys.SEARCH_LISTING_DATA);

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      [SearchFormKeys.service]:
        FORM_DATA?.[SearchFormKeys.service] === "Warehouse" &&
        localFormType === searchFormType.findByTransportForm
          ? "Transport"
          : FORM_DATA?.[SearchFormKeys.service] === "Transport" &&
            localFormType === searchFormType.findByWarehouseForm
          ? "Warehouse"
          : FORM_DATA?.[SearchFormKeys.service] ||
            (localFormType === searchFormType.findByTransportForm
              ? "Transport"
              : "Warehouse"),
      [SearchFormKeys.city]:
        FORM_DATA && FORM_DATA[SearchFormKeys.city] !== ""
          ? FORM_DATA[SearchFormKeys.city]
          : "Bengaluru",
      [SearchFormKeys.truckType]:
        FORM_DATA && FORM_DATA[SearchFormKeys.truckType] !== ""
          ? FORM_DATA[SearchFormKeys.truckType]
          : "light"
    }
  });

  const { refetch, isError, error, isFetching } = useFetchListings(formParams);

  const serviceType = watch(SearchFormKeys.service);

  useEffect(() => {
    if (serviceType === "Transport") {
      setShowTruckTypeSelection(true);
    }
  }, [serviceType]);

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const onSubmit = (data) => {
    const isWarehouseService = Boolean(
      data[SearchFormKeys.service] === "Warehouse"
    );
    const service = {
      ...(isWarehouseService
        ? { warehouse: true, transport: false }
        : { warehouse: false, transport: true })
    };
    Storage.setItem(StorageKeys.serviceLocalStorage, service);
    Storage.setItem(
      StorageKeys.SEARCH_FORM_TYPE,
      isWarehouseService
        ? searchFormType.findByWarehouseForm
        : searchFormType.findByTransportForm
    );
    Storage.setItem(StorageKeys.SEARCH_LISTING_DATA, {
      ...data
    });
    Storage.removeItem(StorageKeys.SEARCH_FORM_DATA);
    Storage.removeItem(StorageKeys.SEARCH_FORM_PARAMS);
    onRemoveFromCart(cartServiceKeys.warehouse);
    onRemoveFromCart(cartServiceKeys.transport);
    const formObject = {
      "searchForm[partnerType]": data[SearchFormKeys.service],
      "searchForm[city]": data[SearchFormKeys.city],
      ...{
        ...(isWarehouseService
          ? {}
          : {
              "searchForm[weightCapacityRange]":
                vehicleWeights[data[SearchFormKeys.truckType]]
            })
      }
    };
    setFormParams(formObject);
    Storage.setItem(StorageKeys.SEARCH_LISTING_PARAMS, formObject);
  };

  useEffect(() => {
    if (formParams) {
      refetch().then((res) => {
        if (res.isSuccess) navigate("/explore/listings");
      });
    }
  }, [formParams, navigate, refetch]);

  useEffect(() => {
    if (isError)
      enqueueSnackbar(
        errorStrings[error?.response.data.data.errors?.[0].context.label] ||
          error?.response.data.message,
        { variant: "error" }
      );
  }, [error?.response.data.data.errors, error?.response.data.message, isError]);

  const containerStyle = {
    display: "flex",
    alignItems: "stretch"
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        sx={{
          padding: {
            xs: "30px 30px",
            sm: "initial"
          },
          paddingBottom: { xs: "70px", sm: 2 },
          mb: { xs: 9 }
        }}
      >
        <Grid container maxWidth={currentUser ? "auto" : "1264px"} spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                md: "flex"
              },
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            <img
              src={ExploreFormBg}
              alt="Search for Service"
              style={{
                position: "sticky",
                top: 100
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
              <Typography
                sx={{ fontSize: "20px", fontWeight: 500, lineHeight: "24px" }}
              >
                Search for <br />
                <span style={{ fontWeight: 700 }}>
                  {" warehouses and Trucks "}
                </span>
                around you
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: { xs: 4, md: 0 } }}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "32px" },
                  fontWeight: 500,
                  lineHeight: { xs: "19.36px", md: "38.73px" },
                  letterSpacing: "0.002em"
                }}
              >
                {"I want to book a"}
              </Typography>
            </Grid>
            <Box sx={{ position: "relative", zIndex: 0 }}>
              {/* Service Selection Grid */}
              <Grid
                container
                columnSpacing={2}
                sx={{
                  mt: { xs: 1, md: 2.5 },
                  ...containerStyle,
                  position: "relative",
                  zIndex: showTruckTypeSelection ? 1 : 10,
                  opacity: showTruckTypeSelection ? 0 : 1,
                  visibility: showTruckTypeSelection ? "hidden" : "visible",
                  transition:
                    "opacity 0.5s ease-in-out, visibility 1s ease-in-out 0.5s",
                  pointerEvents: showTruckTypeSelection ? "none" : "auto"
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "stretch" }}
                >
                  <RadioCard
                    size="200px"
                    mobileSize="140px"
                    name={SearchFormKeys.service}
                    SvgImg={"Warehouse"}
                    label={"Warehouse"}
                    control={control}
                    selectedValue="Warehouse"
                    selectedColor="#2773FF"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  position={"relative"}
                  sx={{ display: "flex", alignItems: "stretch" }}
                >
                  <RadioCard
                    size="200px"
                    mobileSize="140px"
                    name={SearchFormKeys.service}
                    SvgImg={"Transport"}
                    label={"Truck"}
                    control={control}
                    selectedValue="Transport"
                    selectedColor="#F9A90E"
                  />
                  {serviceType === "Transport" && (
                    <Box position={"absolute"} bottom={0} right={0}>
                      <Button
                        variant="link"
                        sx={{
                          height: { xs: "14px", md: "22px" }
                        }}
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                        onClick={() => setShowTruckTypeSelection(true)}
                      >
                        {"Next"}
                        <TrendingFlat />
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>

              {/* Truck Type Selection Grid */}
              <Grid
                container
                columnSpacing={2}
                rowSpacing={1}
                sx={{
                  ...containerStyle,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: showTruckTypeSelection ? 10 : 1,
                  opacity: showTruckTypeSelection ? 1 : 0,
                  transition:
                    "opacity 0.5s ease-in-out, visibility 1s ease-in-out 0.5s",
                  visibility: showTruckTypeSelection ? "visible" : "hidden",
                  pointerEvents: showTruckTypeSelection ? "auto" : "none",
                  background: "#fff",
                  boxShadow: "none"
                }}
              >
                <Grid item xs={12} display={"flex"} alignItems={"center"}>
                  <IconButton onClick={() => setShowTruckTypeSelection(false)}>
                    <ArrowBack />
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", md: "24px" },
                      lineHeight: { xs: "19.36px", md: "29.05px" },
                      fontWeight: 500,
                      ml: 1
                    }}
                  >
                    {"Select Vehicle Type"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", alignItems: "stretch" }}
                >
                  <RadioCard
                    size="150px"
                    mobileSize="90px"
                    name={SearchFormKeys.truckType}
                    SvgImg={"Light"}
                    label={"Light < 1 Ton"}
                    control={control}
                    selectedValue="light"
                    selectedColor="#F9A90E"
                    isTruckType
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", alignItems: "stretch" }}
                >
                  <RadioCard
                    size="150px"
                    mobileSize="90px"
                    name={SearchFormKeys.truckType}
                    SvgImg={"Medium"}
                    label={"Medium 1-5 Ton"}
                    control={control}
                    selectedValue="medium"
                    selectedColor="#F9A90E"
                    isTruckType
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", alignItems: "stretch" }}
                >
                  <RadioCard
                    size="150px"
                    mobileSize="90px"
                    name={SearchFormKeys.truckType}
                    SvgImg={"Heavy"}
                    label={"Heavy > 5 Ton"}
                    control={control}
                    selectedValue="heavy"
                    selectedColor="#F9A90E"
                    isTruckType
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} sx={{ my: 2 }}>
              <Divider
                sx={{
                  border: { xs: "1px solid #B7BEC7", md: "1px solid #607088" }
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: { xs: 4, md: 0 } }}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "32px" },
                  fontWeight: 500,
                  lineHeight: { xs: "19.36px", md: "38.73px" },
                  letterSpacing: "0.002em"
                }}
              >
                {"Select City"}
              </Typography>
            </Grid>
            <Grid
              container
              columnSpacing={2}
              sx={{ mt: { xs: 1, md: 2.5 }, ...containerStyle }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "stretch" }}>
                <RadioCard
                  size="200px"
                  mobileSize="140px"
                  name={SearchFormKeys.city}
                  SvgImg={"Bengaluru"}
                  label={"Bengaluru"}
                  control={control}
                  selectedValue="Bengaluru"
                  selectedColor="#2773FF"
                />
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "stretch" }}>
                <RadioCard
                  size="200px"
                  mobileSize="140px"
                  name={SearchFormKeys.city}
                  SvgImg={"Hyderabad"}
                  label={"Hyderabad"}
                  control={control}
                  selectedValue="Hyderabad"
                  selectedColor="#2773FF"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  lineHeight: { xs: "14.52px", md: "19.36px" },
                  fontWeight: 500,
                  color: "#607088"
                }}
              >
                {"We are coming up in other cities soon."}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: { xs: "none", md: "flex" }, mt: 6 }}
            >
              <Button
                variant="contained"
                sx={{ height: "48px", width: "150px" }}
                onClick={handleSubmit(onSubmit)}
              >
                {"Proceed"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "flex", md: "none" },
          width: "100%",
          position: "fixed",
          bottom: Boolean(currentUser) ? "70px" : 0,
          zIndex: 1
        }}
      >
        <DrawerButton
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{ textTransform: "none" }}
          component={LoadingButton}
          loading={isFetching}
        >
          {"Proceed"}
        </DrawerButton>
      </Grid>
    </>
  );
};

export default Explore;
