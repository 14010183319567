import { Box, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { StyledHeaderText } from "./SaaS";
import MarketplaceChart from "../../assets/Saas_MarketPlace.png";
import styled from "@emotion/styled";

export const StyledText = styled(Typography)((props) => ({
  fontSize: props.isMobile ? "14px" : "22px",
  fontWeight: 400,
  lineHeight: props.isMobile ? "21px" : "33px",
  letterSpacing: "-0.01em",
  textAlign: "justify",
  color: "#607088"
}));

const Marketplace = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      {/* Mobile view */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          padding: "30px 25px 0px 25px",
          gap: 2
        }}
      >
        <StyledHeaderText
          color={"#F9A90E"}
          component="h1"
          isMobile={true}
          textAlign={"center"}
        >
          {`${labels.marketplaceHeaderText1} `}
          <span style={{ color: "#2773FF" }}>
            {labels.marketplaceHeaderText2}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              src={MarketplaceChart}
              alt={"SaaS enabled Marketplace"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <StyledText isMobile>
              A multi tenant cloud based marketplace of{" "}
              <span style={{ color: "#F9A90E" }}>Warehouses and Trucks</span> .
            </StyledText>
            <StyledText isMobile>
              A <span style={{ color: "#F9A90E" }}>One Stop Solution</span> to
              all your Logistics needs.
            </StyledText>
            <StyledText isMobile>
              Solve your{" "}
              <span style={{ color: "#F9A90E" }}>
                fulfillment and distribution
              </span>{" "}
              problems without owning or renting any assets.
            </StyledText>
            <StyledText isMobile>
              Reduce your operational costs by using our{" "}
              <span style={{ color: "#F9A90E" }}>pay-per-use</span> logistics
              solutions.
            </StyledText>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box>
            <iframe
              width="320"
              height="240"
              title="Saas-Marketplace"
              src="https://www.youtube.com/embed/avXCghyXXU8?playlist=avXCghyXXU8&loop=1"
            ></iframe>
          </Box>
          <StyledText isMobile>
            Our portal enables customers to send enquiries and receive
            competitive bids directly from various partners. Customers can
            prepare a detailed packing note and track each package. Our Request
            For Quote system enables customers to negotiate with multiple
            vendors and get services at cheapest prices. Please go through our
            Product-Explainer-Video to know about various features of Warex.{" "}
          </StyledText>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      {/* Web view */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          padding: "30px 70px 0px 80px",
          gap: 4
        }}
      >
        <StyledHeaderText color={"#F9A90E"} component="h1">
          {`${labels.marketplaceHeaderText1} `}
          <span style={{ color: "#2773FF" }}>
            {labels.marketplaceHeaderText2}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
              padding: "0px 70px"
            }}
          >
            <img
              src={MarketplaceChart}
              alt={"SaaS enabled Marketplace"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <StyledText>
              A multi tenant cloud based marketplace of{" "}
              <span style={{ color: "#F9A90E" }}>Warehouses and Trucks</span> .
            </StyledText>
            <StyledText>
              A <span style={{ color: "#F9A90E" }}>One Stop Solution</span> to
              all your Logistics needs.
            </StyledText>
            <StyledText>
              Solve your{" "}
              <span style={{ color: "#F9A90E" }}>
                fulfillment and distribution
              </span>{" "}
              problems without owning or renting any assets.
            </StyledText>
            <StyledText>
              Reduce your operational costs by using our{" "}
              <span style={{ color: "#F9A90E" }}>pay-per-use</span> logistics
              solutions.
            </StyledText>
          </Box>
        </Box>
        <Box display={"flex"} gap={2}>
          <StyledText>
            Our portal enables customers to send enquiries and receive
            competitive bids directly from various partners. Customers can
            prepare a detailed packing note and track each package. Our Request
            For Quote system enables customers to negotiate with multiple
            vendors and get services at cheapest prices. Please go through our
            Product-Explainer-Video to know about various features of Warex.{" "}
          </StyledText>
          <Box>
            <iframe
              loading="lazy"
              width="450"
              height="250"
              title="Saas-Marketplace"
              src="https://www.youtube.com/embed/avXCghyXXU8?playlist=avXCghyXXU8&loop=1"
            ></iframe>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsforDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};
export default Marketplace;
