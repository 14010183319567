import { globalTheme, otherControlsResting } from "./globalTheme";

import { createTheme } from "@mui/material";

export const bottomAppBarTheme = createTheme({
  ...globalTheme,
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        sx: {
          top: "auto",
          bottom: 0,
          zIndex: 1200,
          display: {
            sm: "none"
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "70px"
        }
      },
      defaultProps: {
        sx: {
          display: {
            sm: "none"
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        sx: {
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          color: otherControlsResting
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          // color: theme.palette.secondary.light,
          fontSize: 20,
          marginBottom: 3
        })
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "3px 6px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "0",
            height: "3px",
            backgroundColor: "white",
            borderRadius: "0 0 2px 2px",
            transition: "width 0.3s ease-in-out"
          },
          "&.Mui-selected": {
            // "& > .MuiSvgIcon-root": {
            //   color: "white"
            // },
            "&:before": {
              width: "60%"
            },
            "& > .MuiTypography-root": {
              color: "white",
              fontWeight: 500
            }
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& > .MuiBox-root": {
            textDecoration: "none"
          }
        }
      }
    }
  }
});
