import * as XLSX from "xlsx";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { inventory_table_labels } from "../../constants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useForm } from "react-hook-form";
import { inventory_filterKeys } from "../../constants/formKeys";
import CustomTable from "../../shared/customTable";
import { currencyFormatter, dropdownOptions } from "../../shared/utils";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import Ledger from "../../assets/Ledger.png";
import { useDrawer } from "../../shared/customSideDrawer";
import { PrimaryText, SecondaryText } from "./Performance";
import styled from "@emotion/styled";
import Filter from "../../purchase/components/Filter";
import { FileUploadOutlined, Search, TuneOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCustomDialog } from "../../shared/customDialog";
import ItemLedger from "../components/SKU/ItemLedger";
import AddNewSKu from "../components/SKU/AddNewSKU";
import EditSkuById from "../components/SKU/EditSkuById";
import { useFetchSkus } from "../../query-hooks/Sku/useFetchSkus";
import { useInView } from "react-intersection-observer";
import { useFetchSampleSkuTemplate } from "../../query-hooks/Sku/useFetchSampleSkuTemplate";
import { useExcelUpload } from "../../query-hooks/Sku/useExcelUpload";
import { enqueueSnackbar } from "notistack";
import { useFetchSkuFilters } from "../../query-hooks/Sku/useFetchSkuFilters";
import { SkuMobileCard } from "../components/SKU/SkuMobileCard";
import TextInput from "../../shared/formElements/TextInput";

const TableBoldText = styled(Typography)(({ theme, props }) => ({
  ...props,
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px",
  textAlign: "center",
  fontWeight: 700
}));

const getColumns = ({ onLedgerClick, onSkuClick }) => {
  return [
    {
      Header: "SKU",
      accessor: "sku",
      Cell: ({ row, value }) => {
        return (
          <Box display={"flex"} gap={1}>
            {value?.img && (
              <img
                src={value?.img}
                alt={row?.original.brand}
                style={{ width: "50%", maxWidth: "50%" }}
              />
            )}
            <Button
              variant="link"
              disableRipple
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                textDecoration: "underline",
                color: "#2773FF",
                whiteSpace: "pre-wrap"
              }}
              onClick={() => onSkuClick(row.original.skuId)}
            >
              {value.name}
            </Button>
          </Box>
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <PrimaryText>{`${value.product}`}</PrimaryText>
            <SecondaryText>{`( ${value.units} )`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "Brand",
      accessor: "brand"
    },
    {
      Header: "Category",
      accessor: "category"
    },
    {
      Header: "Attribute1",
      accessor: "attribute1"
    },
    {
      Header: "Attribute2",
      accessor: "attribute2"
    },
    {
      Header: "Stock",
      accessor: "stock",
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <TableBoldText color={"#2773FF"}>{`${value.unit1}`}</TableBoldText>
            <TableBoldText
              color={"#BBCADF"}
            >{`(${value.unit2})`}</TableBoldText>
          </Box>
        );
      }
    },
    {
      Header: "GST",
      accessor: "gst",
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Typography>{`${value.number}`}</Typography>
            <TableBoldText
              color={"#BBCADF"}
            >{`(${value.percent}%)`}</TableBoldText>
          </Box>
        );
      }
    },
    {
      Header: "List Price",
      accessor: "listPrice"
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <CustomTooltip title={"Ledger"} placement="top">
            <IconButton onClick={() => onLedgerClick(row.original.skuId)}>
              <img
                src={Ledger}
                className="pull-right cursor-field"
                alt={value}
              />
            </IconButton>
          </CustomTooltip>
        );
      }
    }
  ];
};

const getRows = (rows = [], refetch) => {
  return rows.map((item, index) => {
    return {
      skuId: item._id,
      sku: { img: item?.image?.thumbUrl, name: item?.skuCode },
      product: {
        product: item?.productName,
        units: item?.unit?.name
      },
      brand: item?.brandName ?? "-",
      category: item?.categoryName ?? "-",
      attribute1: item?.customAttributes[0]?.value ?? "-",
      attribute2: item?.customAttributes[1]?.value ?? "-",
      stock: {
        unit1: item?.stock?.closingQuantity,
        unit2: item?.minStockLevel
      },
      gst: {
        number: item?.hsn ?? `--`,
        percent: item?.gstRegime
      },
      listPrice: currencyFormatter(item?.rate),
      refetch: () => refetch()
    };
  });
};

const downloadExcelFile = (excelTemplate, fileName) => {
  const uint8Array = new Uint8Array(excelTemplate);
  const binaryString = uint8Array.reduce(
    (acc, byte) => acc + String.fromCharCode(byte),
    ""
  );
  const workbook = XLSX.read(binaryString, { type: "binary" });
  XLSX.writeFile(workbook, fileName);
};

const Sku = () => {
  const [params, setParams] = useState();
  const { control, reset, handleSubmit } = useForm();
  const fileInputRef = useRef(null);
  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog } = useCustomDialog();
  const { ref, inView } = useInView();

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchSkuFilters();

  const {
    data,
    isFetching,
    isFetched,
    refetch,
    hasNextPage,
    fetchNextPage,
    isError,
    error
  } = useFetchSkus(params);

  const { data: excelTemplate, isFetched: isFetchedTemplate } =
    useFetchSampleSkuTemplate();

  const {
    mutate: uploadExcel,
    isError: isUploadExcelError,
    error: uploadExcelError
  } = useExcelUpload();

  const onDownloadClick = () => {
    if (isFetchedTemplate) {
      downloadExcelFile(excelTemplate, "template.xlsx");
    }
  };

  const handleExcelUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const skuFile = new FormData();
      skuFile.append("file", file);
      uploadExcel(
        { data: skuFile },
        {
          onSuccess: (res) => {
            refetch();
            enqueueSnackbar("Excel Uploaded!", { variant: "success" });
          }
        }
      );
    }
  };

  const onLedgerClick = useCallback(
    (row) => {
      showDialog({
        component: <ItemLedger skuId={row} />,
        size: "xl",
        backdropOff: true,
        closeIcon: true
      });
    },
    [showDialog]
  );

  const onSkuClick = useCallback(
    (skuId) => {
      showDrawer({
        component: (
          <EditSkuById
            skuId={skuId}
            hideDrawer={hideDrawer}
            refetch={refetch}
          />
        ),
        closeIcon: false
      });
    },
    [showDrawer, hideDrawer, refetch]
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const skuTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns({ onLedgerClick, onSkuClick })}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.skus, refetch)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={SkuMobileCard}
      />
    ),
    [data?.pages, isFetched, onLedgerClick, onSkuClick, refetch]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["warehouse"] !== ""
          ? {
              [`filters[warehouse]`]: data["warehouse"]
            }
          : {})
      },
      ...{
        ...(data["brands"] !== ""
          ? {
              [`filters[brandName]`]: data["brands"]
            }
          : {})
      },
      ...{
        ...(data["category"] !== ""
          ? {
              [`filters[categoryName]`]: data["category"]
            }
          : {})
      },
      ...{
        ...(data["product"] !== ""
          ? {
              [`filters[productName]`]: data["product"]
            }
          : {})
      },
      ...{
        ...(data["sku"] !== ""
          ? {
              [`filters[sku]`]: data["sku"]
            }
          : {})
      }
    };
    setParams(filterData);
    hideDrawer();
  };

  const onClear = () => {
    reset({
      warehouse: "",
      brands: "",
      category: "",
      product: "",
      sku: ""
    });
    setParams(null);
  };

  const FilterComponent = (
    <Box>
      <Filter
        control={control}
        onApply={handleSubmit(onViewClick)}
        onClear={onClear}
        isFetching={isFilterFetching}
        isLoading={isFetching}
        filters={[
          {
            type: "DropDown",
            name: inventory_filterKeys.warehouse,
            label: inventory_table_labels.warehouse,
            unSelectText: "Select Warehouse",
            options: dropdownOptions(filterData?.warehouses, "_id", "code"),
            filterCount: filterData?.warehouses?.length
          },
          {
            type: "DropDown",
            name: inventory_filterKeys.brands,
            label: inventory_table_labels.brands,
            unSelectText: "Select Brand",
            options: dropdownOptions(filterData?.brandNames),
            filterCount: filterData?.brandNames?.length
          },
          {
            type: "DropDown",
            name: inventory_filterKeys.category,
            label: inventory_table_labels.category,
            unSelectText: "Select Category",
            options: dropdownOptions(filterData?.categoryNames),
            filterCount: filterData?.categoryNames?.length
          },
          {
            type: "DropDown",
            name: inventory_filterKeys.product,
            label: inventory_table_labels.product,
            unSelectText: "Select Product",
            options: dropdownOptions(filterData?.productNames),
            filterCount: filterData?.productNames?.length
          },
          {
            type: "DropDown",
            name: inventory_filterKeys.sku,
            label: inventory_table_labels.sku,
            unSelectText: "Select SKU",
            allowSearch: true,
            options: dropdownOptions(filterData?.skus, "_id", "skuCode"),
            filterCount: filterData?.skus?.length
          }
        ]}
      />
    </Box>
  );

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isUploadExcelError) {
      enqueueSnackbar(uploadExcelError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    uploadExcelError?.response?.data?.message,
    isUploadExcelError,
    error?.response?.data?.message,
    isError
  ]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", lg: "flex" },
          position: "sticky",
          top: { xs: 0, md: "64px" },
          zIndex: 1000,
          background: "#fff",
          padding: { xs: "10px 20px", md: "16px 0" }
        }}
      >
        <Box
          sx={{ display: { xs: "none", lg: "flex" } }}
          justifyContent={"flex-end"}
          alignItems={"start"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept=".xlsx"
            />
            <Button
              variant="link"
              disableRipple
              sx={{
                padding: 2,
                height: 0,
                whiteSpace: "nowrap"
              }}
              onClick={handleExcelUpload}
            >
              <FileUploadOutlined /> {inventory_table_labels.excelUpload}
            </Button>
            <Button
              variant="link"
              disableRipple
              sx={{
                color: "#F9A90E",
                fontSize: "12px",
                fontWeight: 400,
                textDecorationLine: "underline",
                paddingLeft: 1
              }}
              onClick={onDownloadClick}
            >
              {inventory_table_labels.templateDownload}
            </Button>
          </Box>
          <Button
            variant="link"
            disableRipple
            sx={{
              padding: 2,
              height: 0,
              whiteSpace: "nowrap"
            }}
            onClick={() => {
              showDrawer({
                component: (
                  <AddNewSKu hideDrawer={hideDrawer} refetch={refetch} />
                ),
                closeIcon: false
              });
            }}
          >
            <AddOutlinedIcon />
            {inventory_table_labels.addNewSku}
          </Button>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          {FilterComponent}
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <TextInput
            startIcon={
              <Search sx={{ ml: 2, fontSize: 25, color: "text.disabled" }} />
            }
            control={control}
            name={"search"}
            placeholder={"Search by SKU ID, Product Name"}
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: 700
            }}
            onClick={() => {
              showDrawer({
                height: "fit-content",
                component: (
                  <AddNewSKu hideDrawer={hideDrawer} refetch={refetch} />
                ),
                closeIcon: false
              });
            }}
          >
            {inventory_table_labels.Sku}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{skuTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Sku;
