import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import Inventory from "../../assets/Inventory_erp.jpg";
import Register from "../../assets/Register_erp.png";
import Vendor from "../../assets/Vendor_erp.jpg";
import SaleOrder from "../../assets/SaleOrder_erp.jpg";
import Performance from "../../assets/Performance_erp.jpg";
import Finance from "../../assets/Finance_erp.jpg";
import ProductFeatureItem from "./ProductFeatureItem";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import appRoutes from "../../constants/appRoutes";

const product = [
  {
    label: home_labels.Inventory,
    subtext: home_labels.InventorySubTextonly,
    image: Inventory,
    padding: "13.75px",
    id: "Inventory"
  },
  {
    label: home_labels.Register,
    subtext: home_labels.RegisterSubTextonly,
    image: Register,
    padding: "13.75px",
    id: "Register"
  },
  {
    label: home_labels.Vendor,
    subtext: home_labels.VendorSubTextonly,
    image: Vendor,
    padding: "13.75px",
    id: "Vendor"
  },
  {
    label: home_labels.SaleOrder,
    subtext: home_labels.SaleOrderSubTextonly,
    image: SaleOrder,
    padding: "13.75px",
    id: "SaleOrder"
  },
  {
    label: home_labels.Performance,
    subtext: home_labels.PerformanceSubTextonly,
    image: Performance,
    padding: "195px",
    id: "Performance"
  },
  {
    label: home_labels.Finance,
    subtext: home_labels.FinanceSubTextonly,
    image: Finance,
    padding: "270px",
    id: "Finance"
  }
];

export const ERPSection = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const sliderRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      const entry = entries[0];
      setIsInView(entry.intersectionRatio >= 0.5);
    };

    const observerOptions = {
      threshold: [0.5]
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      if (isInView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [isInView]);

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current && isInView) {
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false, // Default to false
    autoplaySpeed: 2000
  };

  return (
    <Box
      id="erp-section"
      ref={sectionRef}
      display="flex"
      flexDirection="column"
      textAlign="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        background: "#F7F8FA",
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "0 20px",
          md: "5px 65px"
        },
        gap: { xs: 0, md: 2 }
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        sx={{
          position: "relative"
        }}
      >
        <Box>
          <SectionHeader
            title={home_labels.WarexERP}
            props={{ fontWeight: { xs: 400, md: 700 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            paddingTop: { xs: "10px", md: 0 }
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "80px", md: "150px" },
              padding: { xs: "0px" },
              height: { xs: "30px", md: "60px" },
              fontSize: { xs: "10px", md: "17px" },
              lineHeight: { xs: "12px", md: "20px" },
              borderRadius: "4px",
              border: "1px 0px 0px 0px",
              opacity: "0.99px"
            }}
            onClick={() => navigate(appRoutes.erp)}
          >
            {"Know More"}
          </Button>
        </Box>
      </Box>
      <Slider ref={sliderRef} {...settings}>
        {product.map((item) => (
          <div key={item.label}>
            <Grid item xs={12} md={4} style={{ marginRight: "16px" }}>
              <ProductFeatureItem {...item} />
            </Grid>
          </div>
        ))}
      </Slider>
    </Box>
  );
};
