import { Box, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import CreditInvoice from "../../assets/Credit_Invoice.jpeg";
import { useEffect } from "react";

const Credit = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });
  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" },
          gap: 2
        }}
      >
        <Typography
          sx={{
            color: "#F9A90E",
            fontSize: { xs: "20px", md: "36px" },
            lineHeight: { xs: "30px", md: "54px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {`${labels.creditHeaderText1} `}
          <span style={{ color: "#2773FF" }}>{labels.creditHeaderText2}</span>
        </Typography>
        <Box
          display={"flex"}
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
            padding: { xs: 0, md: "0px 90px" }
          }}
        >
          <img
            src={CreditInvoice}
            alt={"Credit Invoice"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain" // Ensures the entire image fits within the box
            }}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} mb={2}>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "14px", md: "22px" },
              lineHeight: { xs: "21px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={400}
          >
            <span style={{ color: "#2773FF" }}>
              {" "}
              Reduce your working capital
            </span>{" "}
            burden by getting your receivables paid in advance.
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "14px", md: "22px" },
              lineHeight: { xs: "21px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={400}
          >
            Serve<span style={{ color: "#2773FF" }}> more customers</span>
            without any financial distress.
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "14px", md: "22px" },
              lineHeight: { xs: "21px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={400}
          >
            Our partnered <span style={{ color: "#2773FF" }}> NBFCs</span> will
            facilitate credit based on your credentials and Warex's
            <span style={{ color: "#2773FF" }}> platform usage.</span>
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify",
            mb: { xs: 0, md: -4 }
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};
export default Credit;
