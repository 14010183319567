import { Box, Typography } from "@mui/material";

import { home_labels } from "../../constants";
import { isMobileDevice } from "../../App";

const LandingHeader = () => {
  return (
    <Box
      className="header_group"
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: { xs: "10px", md: "28px" } }}
    >
      <Typography
        component={isMobileDevice ? "p" : "span"}
        sx={{
          fontSize: {
            xs: 24,
            md: 70
          },
          lineHeight: {
            xs: "40px",
            md: "104px"
          },
          fontWeight: 700,
          letterSpacing: {
            xs: "-0.48px",
            md: "-1.4px"
          },
          color: "text.title"
        }}
      >
        {home_labels.titleText}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 20
          },
          lineHeight: {
            xs: "21px",
            md: "64px"
          },
          letterSpacing: {
            xs: "-0.14px",
            md: "-0.4px"
          },
          color: {
            xs: "text.secondary",
            md: "text.primary"
          },
          mt: "10px",
          fontWeight: "400",
          textAlign: "justify"
        }}
      >
        {home_labels.titleSubtext}
      </Typography>
    </Box>
  );
};

export default LandingHeader;
