import { Box, Typography } from "@mui/material";

const SolutionCardItem = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"flex-start"}
      sx={{
        padding: "63px 28px",
        background: "#F7F8FA",
        width: "100%",
        borderRight: "2px solid white",
        "&:last-child": {
          borderRight: 0
        },
        "&:first-of-type": {
          paddingLeft: 0
        },
        maxHeight: 227
      }}
    >
      <img src={props.icon} alt={props.text} height={"auto"} width={"auto"} />
      <Box display={"flex"} flexDirection={"column"} marginLeft={"30px"}>
        <Typography
          fontSize={20}
          lineHeight={"24px"}
          fontWeight={500}
          letterSpacing={"0.002em"}
          color="text.primary"
          gutterBottom
        >
          {props.text}
        </Typography>
        <Typography
          sx={{ maxWidth: 219 }}
          fontSize={15}
          lineHeight={"24px"}
          fontWeight={400}
          color="text.secondary"
        >
          {props.subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default SolutionCardItem;
