const appRoutes = {
  home: "/",
  dashboard: "/dashboard",
  packersAndMovers: {
    hyderabad: "/packers-and-movers-hyderabad",
    bengaluru: "/packers-and-movers-bengaluru"
  },
  profile: {
    main: "/profile",
    changePassword: "/profile/change-password",
    helpAndSupport: "/profile/help-and-support",
    faqs: "/profile/faqs",
    tnc: "/profile/terms-and-conditions"
  },
  explore: {
    main: "/explore",
    warehouseSearchForm: "/explore/warehouse-search-form",
    transporterSearchForm: "/explore/transporter-search-form",
    listings: "/explore/listings",
    serviceDetails: "/explore/listings/details/:service/:uuid",
    serviceDetailsRedirection: "/explore/listings/details/"
  },
  completeOrder: {
    serviceBookOrder: "/explore/complete-book-order/:service/:uuid/:isInquiry?/:inquiryId?",
    serviceBookOrderRedirection: "/explore/complete-book-order/",
    warehouseAndTransporterOrder:
      "/explore/complete-book-order/warehouse/:wUuid/transport/:tUuid"
  },
  inquiry: {
    inquiryListView: "/inquiry"
  },
  orders: {
    main: "/orders",
    myOrdersListView: "my-orders",
    myOrdersDetailView: "/:uuid",
    myOrdersCreatePackingNotes: "/:uuid/packing-notes",
    myOrdersRetrieveOrders: "/retrieve-orders/:uuid",
    myOrdersCreatePackingNotesRedirect: "/packing-notes",
    myOrdersRetrieveOrdersRedirect: "/retrieve-orders/",
    invoicesDocsListView: "invoices-docs",
    retrieveListView: "retrieve"
  },
  inventory: {
    main: "/inventory",
    itemLedger: "item-ledger/:Id",
    sku: "SKUs",
    performance: "performance"
  },
  warehouse: {
    main: "/Warehouse",
    assets: "assets",
    register: "register",
    sku: "SKUs",
    performance: "performance",
    createMrr: "Material-Received-Report/:orderId",
    gatePass: "Gate-Pass/:orderId"
  },
  purchase: {
    main: "/purchase",
    vendors: "vendors",
    addVendor: "add",
    purchaseOrders: "purchase-orders",
    stockAdjustment: "stock-adjustments",
    stockTransfer: "stock-Transfer",
    createOrder: "create-order",
    createReturn: "create-return",
    manageOrder: ":orderId/:manageOrderType/:returnId?"
  },
  sales: {
    main: "/sales",
    customers: "customers",
    addCustomerDetails: "add-customers",
    quotes: "quotes",
    createQuotes: "create-quotes",
    salesOrders: "sales-orders",
    createOrder: "create-order",
    createReturn: "create-return",
    manageOrder: ":orderId/:manageOrderType/:returnId?",
    allocation: "allocation"
  },
  finance: {
    main: "/finance",
    payments: "payments",
    invoices: "invoices",
    createInvoice: "create-invoice"
  },
  settings: "/settings",
  payment: "/payment",
  faqs: "/faqs",
  pricing: "/pricing-scaas",
  saas: "/supply-chain-saas",
  marketplace: "/marketplace-logistics",
  inventoryMangement: "/easy-to-use-inventory-management-software",
  credit: "/credit-finance-sme",
  erp: "/simple-erp-software"
};

export default appRoutes;
