import { Box, Typography } from "@mui/material";

const SolutionCardItemMobile = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        padding: "24px 12px",
        background: "#F7F8FA",
        width: "100%",
        height: "100%",
      }}
    >
      <img src={props.icon} alt={props.text} width={"44px"} height={"44px"}/>
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginTop={"18px"}
        textAlign={"center"}
      >
        <Typography
          fontSize={16}
          lineHeight={"19px"}
          fontWeight={500}
          letterSpacing={"0.005em"}
          color="text.primary"
          gutterBottom
        >
          {props.text}
        </Typography>
        <Typography
          fontSize={13}
          lineHeight={"18px"}
          fontWeight={400}
          color="text.secondary"
        >
          {props.subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default SolutionCardItemMobile;
